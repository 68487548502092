//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ListItem",
    props: {
        header: {
            type: [String, Number]
        },
        title: {
            type: [String, Number],
            required: true
        },
        highlights: {
            type: String,
            required: false,
            default: ''
        },
        footer: {
            type: [String, Number]
        },
        link: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        chevron: {
            type: Boolean,
            default: true
        },
        circles: {
            type: Boolean,
            default: false
        },
        noHover: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        compact: {
            type: Boolean,
            default: false
        },
        close: {
            type: [Boolean, String],
            default: false
        },
        withHighlights: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showTitle: false,
        }
    },        
    computed: {
        has_media_slot () {
            return !!this.$slots['media'];
        },
        has_media_after () {
            return !!this.$slots['after'];
        },
        classes() {
            return {
                link: this.link,
                active: this.active,
                'no-hover': this.noHover || this.disabled,
                disabled: this.disabled,
                compact: this.compact,
            }
        },
        stringWithHighlights() {
            const re = new RegExp(this.highlights, "g")
            return this.title.replace(re, `<span class="highlighted">${this.highlights}</span>`)
        }
    },
    methods: {
        clear () {
            this.$emit('clear')
        },
        mouseOver(e) {
            const spanText = e.target
            const parent = e.target.offsetParent
            parent.offsetWidth < spanText.scrollWidth ? this.showTitle = true : this.showTitle = false
        },
    }
}
