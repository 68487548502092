var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("video", {
    ref: "video",
    staticClass: "custom-video",
    attrs: {
      src: _vm.src,
      preload: _vm.preload,
      playsinline: _vm.playsinline,
      autoplay: _vm.compAutoplay,
      loop: _vm.loop,
      controls: _vm.controls,
      controlsList: "nodownload"
    },
    domProps: { muted: _vm.muted },
    on: {
      ended: function($event) {
        return _vm.$emit("ended")
      },
      playing: function($event) {
        return _vm.$emit("playing")
      },
      loadedmetadata: function($event) {
        return _vm.$emit("loadedmetadata")
      },
      webkitfullscreenchange: function($event) {
        return _vm.$emit(
          "fullscreenchange",
          (_vm.isFullScreen = !_vm.isFullScreen)
        )
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }