//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import {
    INFO,
    PHONE_CNTL,
    TETRA,
} from '../../store/modulesNames'
import {
    GET_TETRA_USER_STATUS,
} from '../../store/gettersTypes'
import {
    ACT_INFO_PUSH,
    ACT_INFO_CLEAR,
    ACT_TETRA_GET_USER_STATUS,
    CALLS_CONTROLLER,
} from '../../store/actionsTypes'

import ActionsContainer from "./ActionsContainer.vue"
import InfoMainBlock from "./InfoMainBlock.vue"
import InfoIcon from "./InfoIcon.vue"
import ListItem from "../custom/ListItem.vue"
import {PHONE_TYPES} from '../../constants'

export default {
    name: "TetraContactInfo",
    props: ['params', 'canBack'],
    components: {
        ActionsContainer,
        InfoMainBlock,
        ListItem,
        InfoIcon,
    },
    data() {
        return {
        }
    },
    created() {
        this[ACT_TETRA_GET_USER_STATUS](this.params.ssi)
    },
    computed: {
        ssi() { return this.params.ssi },
        fio() { return this.params.name },
        note() { return this.params.note },
        status() {
            const ssiStatus = this[GET_TETRA_USER_STATUS](this.ssi)
            const langTetraUserStatus = this.$t('tetra-user-status')
            return langTetraUserStatus[ssiStatus] || langTetraUserStatus['unknown']
        },
        btnClass() {
            return this.params.cid ? 'shrink' : ''
        },
        ...mapGetters(TETRA, [GET_TETRA_USER_STATUS])
    },
    methods: {
        tetraCall(params) {
            this[CALLS_CONTROLLER]({type: PHONE_TYPES.TETRA, data: { ssi: this.ssi }})
        },
        sendSDS() {
            let data = {}
            data.params = { ssi: this.ssi, name: this.fio }
            this.$bus.$emit('open-tetra-chat', data)
        },
        ...mapActions(INFO, [ACT_INFO_PUSH, ACT_INFO_CLEAR]),
        ...mapActions(TETRA, [ACT_TETRA_GET_USER_STATUS]),
        ...mapActions(PHONE_CNTL, [CALLS_CONTROLLER]),
    },
    watch: {
        ssi(val) {
            this[ACT_TETRA_GET_USER_STATUS](val)
        }
    }
}
