export default {
    emitters: {
        addConference(params) {
            return new Promise((resolve, reject) => {
                this.log('add-conference: > ' + JSON.stringify(params))
                this.socket.emit('add-conference', params, result => {
                    if (result.error) console.log(result.error)
                    else resolve(result.roomId)
                })
            })
        },
        conferenceProcessing(params) {
            this.log('conference-processing: > ' + JSON.stringify(params))
            this.socket.emit('conference-processing', params)
        },
        conferenceRinging(params) {
            this.log('conference-ringing: > ' + JSON.stringify(params))
            this.socket.emit('conference-ringing', params)
        },
        conferenceAnsver(params) {
            this.log('conference-answer: > ' + JSON.stringify(params))
            this.socket.emit('conference-answer', params)
        },
        conferenceTermination(params) {
            this.log('conference-termination: > ' + JSON.stringify(params))
            this.socket.emit('conference-termination', params)
        },
        ConferenceAddUser(params) {
            return new Promise((resolve, reject) => {
                this.log('conference-add-users: > ' + JSON.stringify(params))
                this.socket.emit('conference-add-users', params, res => {
                    if (!res.error) resolve(true)
                    else {
                        resolve(false)
                        console.log(res.error)
                    }
                })
            })
        },
    },
    listeners: {
        'conference-event'(data) {
            this.log('bind: < conference-event: ' + JSON.stringify(data))
            this.emit('conference-event', data)
        },
        'conference-ringing-event'(data) {
            this.log('bind: < conference-ringing-event: ' + JSON.stringify(data))
            this.emit('conference-ringing-event', data)
        },
        'conference-answer-event'(data) {
            this.log('bind: < conference-answer-event: ' + JSON.stringify(data))
            this.emit('conference-answer-event', data)
        },
        'conference-processing-event'(data) {
            this.log('bind: < conference-processing-event: ' + JSON.stringify(data))
            this.emit('conference-processing-event', data)
        },
        'conference-termination-event'(data) {
            this.log('bind: < conference-termination-event: ' + JSON.stringify(data))
            this.emit('conference-termination-event', data)
        },
    },
}