'use strict'

import {
    GET_CALLS,
    GET_STATUS,
    GET_MICROPHONE,
    GET_SPEAKER,
    GET_ASISTANT_DND,
    GET_TYPE_ASISTANT,
    GET_ASSISTANTS,
    GET_ACTIVE_CALL,
    GET_NUMBER_ASSISTANT,
    GET_ACTIVE_NUMBER,
    GET_ASSISTANT_CALL_BY_ID,
    GET_ASSISTANT_CALL_PARTICIPANTS,
    GET_SERVER_API,
    GET_PHONE_CALL,
} from "../gettersTypes";
import {
    ACT_UPDATE_CALLS,
    ACT_UPDATE_TA,
    ACT_GET_TA,
    ACT_GET_CALL_INFO,
    ACT_GET_CALLS_INFO,
    ACT_TOGGLE_MICROPHONE,
    ACT_TOGGLE_SPEAKER,
    ACT_TOGGLE_DND,
    ACT_PRESS_DIAL_KEY,
    ACT_DIAL_NUMBER,
    ACT_SET_ACTIVE_CALL,
    ACT_TRANSIT,
    ACT_CONFERENCE,
    ACT_TERMINATE_ASISTANT,
    ACT_SELECT_ASSISTANT,
    ACT_UPDATE_CALL,
    ACT_GET_CONTACT,
    CALLS_EVENTS_CONTROLLER,
    ACT_CALL_ASSISTANT,
    ACT_DELETE_ANSWERED_CALL,
    ACT_TERMINATE_INCOMMING_CALL,
    ACT_PHONE_SHOW,
    ACT_UPDATE_CALL_ASSISTANT,
    ACT_SHOW_SYSTEM_NOTIFICATION,
    ACT_HIDE_SYSTEM_NOTIFICATION,
    ACT_PHONE_HIDE,
    ACT_SET_ANSWERED_CALL_STATUS,
    ACT_ASSISTANT_TERMINATE_ALL_CALLS,
    ACT_ASSISTANT_TERMINATE_CALL,
    ACT_CONF_LIST_UPDATE_ASSISTANT,
    ACT_ADD_INCOMMING_CALL,
} from '../actionsTypes'

import {
    MUT_UPDATE_CALL,
    MUT_UPDATE_STATUS,
    MUT_UPDATE_MICROPHONE,
    MUT_UPDATE_SPEAKER,
    MUT_UPDATE_DND,
    MUT_CLEAR_DATA,
    MUT_SAVE_ASSISTANTS,
    MUT_SAVE_CONTACT,
    MUT_SAVE_ACTIVE_NUMBER,
    MUT_DELETE_CALL,
    MUT_ADD_TRANSIT_ID,
    MUT_ASSISTANT_UPDATE_CALL_PARTICIPANTS
} from '../mutationsTypes'

import {
    LOGIN,
    NOTIFICATIONS,
    PHONE_CNTL,
} from '../modulesNames'

import Vue from 'vue'
import { i18n } from '../../../ext/i18n'

import { PHONE_TYPES } from '../../constants'
import moment from "moment"

const state = {
    calls: [],
    status: '',
    microphone: false,
    speaker: false,
    dnd: false,
    contact: {
        name: '',
        icon: ''
    },
    typeAssistant: '',
    assistantsList: [],
    activeNumber: '',
    transitingId: []
}

const getters = {
    [GET_CALLS]: state => state.calls,
    [GET_ASSISTANT_CALL_BY_ID]: state => id => state.calls.find(call => call.id === id),
    [GET_STATUS]: state => state.status,
    [GET_MICROPHONE]: state => state.microphone,
    [GET_SPEAKER]: state => state.speaker,
    [GET_ASISTANT_DND]: state => state.dnd,
    [GET_TYPE_ASISTANT]: state => state.typeAssistant,
    [GET_ASSISTANTS]: state => [{ type: 'roschat' }, ...state.assistantsList],
    [GET_ACTIVE_CALL]: state => {
        let res = state.calls.find(item => {
            if ('active' in item && item.active) return true
        })
        if (!res && state.calls.length) res = state.calls[0]
        return res
    },
    [GET_NUMBER_ASSISTANT]: state => assistant => {
        let number
        state.assistantsList.forEach(item => {
            if (item.type === assistant && 'number' in item) number = item.number
        })
        return number
    },
    [GET_ACTIVE_NUMBER]: state => state.activeNumber,
    [GET_ASSISTANT_CALL_PARTICIPANTS]: (state, getters) => id => {
        const call = getters[GET_ASSISTANT_CALL_BY_ID](id)
        if (call && call.participants) {
            return call.participants
        } else if (call) {
            return [{number: call.digits}]
        } else {
            return []
        }
    },
};

const actions = {
    // ------------------ Abstract actions --------------------------
    [ACT_GET_CALL_INFO]: () => {},
    [ACT_GET_CALLS_INFO]: () => {},
    [ACT_GET_TA]: () => {},
    [ACT_TOGGLE_MICROPHONE]: () => {},
    [ACT_TOGGLE_SPEAKER]: () => {},
    [ACT_TOGGLE_DND]: () => {},
    [ACT_PRESS_DIAL_KEY]: () => {},
    [ACT_DIAL_NUMBER]:  () => {},
    [ACT_SET_ACTIVE_CALL]:  () => {},
    [ACT_TERMINATE_ASISTANT]: () => {},
    [ACT_TRANSIT]: () => {},
    [ACT_CONFERENCE]: () => {},
    [ACT_SELECT_ASSISTANT]: () => {},
    // --------------------------------------------------------------
    [ACT_UPDATE_CALLS]: ({ commit, dispatch }, calls) => {
        if (!!!calls.forEach) return
        calls.forEach(call => {            
            if (['incomming', 'talk', 'dialing', 'hold'].includes(call.state)) dispatch(ACT_UPDATE_CALL, call)
        })
    },
    [ACT_UPDATE_CALL]: ({ state, commit, getters, dispatch }, call) => {

        switch (call.state) {
            case 'incoming':
                // if (call.digits) {
                    dispatch(`${PHONE_CNTL}/${ACT_ADD_INCOMMING_CALL}`, {
                        id: call.id,
                        type: PHONE_TYPES.ASSISTANT,
                        data: {
                            id: call.id,
                            type: PHONE_TYPES.ASSISTANT,
                            isIncomming: call.type === 'in',
                            number: call.digits,
                            typeCall: i18n.t(`assistants.name.${state.typeAssistant}`, { number: call.digits ? `(${call.digits})` : call.digits }),
                            typeAssistant: state.typeAssistant
                        }
                    }, { root: true })
                // }
                dispatch(ACT_UPDATE_CALL_ASSISTANT, call)
                break
            case 'ready':
            case 'talk':
            case 'dialing':
            case 'pick-up':
                dispatch(`${PHONE_CNTL}/${ACT_PHONE_SHOW}`, { type: PHONE_TYPES.ASSISTANT, id: call.id, isIncomming: call.type === 'in', data: call }, { root: true })
                dispatch(ACT_UPDATE_CALL_ASSISTANT, call)
                break
            case 'dial-busy':
            case 'dial-error':
            case 'dial-unreach':
            case 'released':                
                dispatch(`${PHONE_CNTL}/${ACT_TERMINATE_INCOMMING_CALL}`, { type: PHONE_TYPES.ASSISTANT, id: call.id, remoteTerminate: true }, { root: true })
                let activeCall = getters[GET_ACTIVE_CALL]
                if (state.calls.length === 1 && !state.transitingId.includes(activeCall.id) && activeCall && activeCall.id === call.id) {
                    call.active = true                   
                    dispatch(ACT_UPDATE_CALL_ASSISTANT, call)
                    setTimeout(() => {
                        dispatch(ACT_ASSISTANT_TERMINATE_CALL, call)
                    }, 1500)
                } else {
                    dispatch(ACT_ASSISTANT_TERMINATE_CALL, call)
                }
                break
            default:
                dispatch(ACT_UPDATE_CALL_ASSISTANT, call)
                break         
        }   
    },
    [ACT_ASSISTANT_TERMINATE_CALL]: ({dispatch, commit}, call) => {
        dispatch(`${PHONE_CNTL}/${ACT_DELETE_ANSWERED_CALL}`, { type: PHONE_TYPES.ASSISTANT, id: call.id }, { root: true })
        commit(MUT_DELETE_CALL, call.id)
    },
    [ACT_ASSISTANT_TERMINATE_ALL_CALLS]: ({getters, dispatch}) => {
         getters[GET_CALLS].forEach(call => dispatch(ACT_ASSISTANT_TERMINATE_CALL, call))
    },
    [ACT_UPDATE_TA]: ({ state, commit, dispatch }, data) => {
        if (data.status === 'disconnected') { 
            dispatch(`${PHONE_CNTL}/${ACT_PHONE_HIDE}`, { type: PHONE_TYPES.ASSISTANT }, { root: true })
            if (['', 'none', 'roschat', 'sip'].includes(state.typeAssistant)) return
            dispatch(`${NOTIFICATIONS}/${ACT_SHOW_SYSTEM_NOTIFICATION}`, { 
                title: i18n.t(`assistants.name.${state.typeAssistant}`, { number: '' }),
                text: i18n.t('assistants.dial-error') 
            }, { root: true })            
        } else if (data.status === 'ok') dispatch(`${NOTIFICATIONS}/${ACT_HIDE_SYSTEM_NOTIFICATION}`, null, { root: true })

        commit(MUT_UPDATE_STATUS, data.status)
        commit(MUT_UPDATE_MICROPHONE, data.microphone || false)
        commit(MUT_UPDATE_SPEAKER, data.speaker || false)
        commit(MUT_UPDATE_DND, data.dnd || false)       
    },
    [ACT_CALL_ASSISTANT]: ({ dispatch, commit }, { number, id }) => {
        commit(MUT_SAVE_ACTIVE_NUMBER, number)
    },
    [ACT_GET_CONTACT]: ({ rootGetters, commit }, number) => {
        let contact = rootGetters['contacts/getMergedContactByPhone'](number)
        if (contact && contact.fio) return { name: contact.fio, icon: contact.photo }
        else return { name: number, icon: '' }
    },
    [ACT_UPDATE_CALL_ASSISTANT]: async ({state, commit, dispatch, rootGetters}, call) => {
        let active = ('active' in call && call.active)
        if (active) dispatch(ACT_CALL_ASSISTANT, { number: call.digits })
        
        call.contact = await dispatch(ACT_GET_CONTACT, call.digits)

        commit(MUT_UPDATE_CALL, call)
        let updateData = {}
        if (active) updateData.active = active
        if (call.state === 'talk') updateData.status = 'talk'
        if (call.connectedTime) {
            if (rootGetters[`${LOGIN}/${GET_SERVER_API}`] >= declarations.serverAPILevels.LEVEL_9) {
                updateData.talkStartTime = moment().unix() - call.connectedTime
            } else {
                updateData.talkStartTime = call.connectedTime
            }
        }
        if (Object.keys(updateData)) dispatch(`${PHONE_CNTL}/${ACT_SET_ANSWERED_CALL_STATUS}`, { type: PHONE_TYPES.ASSISTANT, id: call.id, ...updateData }, { root: true })
    },
    [ACT_CONF_LIST_UPDATE_ASSISTANT]({commit, getters, dispatch}, payload) {
        commit(MUT_ASSISTANT_UPDATE_CALL_PARTICIPANTS, { id: payload.id, participants: payload.list })
    },
}

const mutations = {
    [MUT_UPDATE_CALL]: (state, call) => {
        let index = state.calls.findIndex(({ id }) => id === call.id)
        if (index > -1) {
            let oldCall = state.calls[index]
            if (oldCall.participants && !call.participants) call.participants = oldCall.participants
            Vue.set(state.calls, index, call)
        }else state.calls.push(call)

        if ('active' in call) {
            state.calls.forEach((item, i) => {
                if ('active' in item && item.id !== call.id) delete item.active
            })
            state.calls.forEach((item, i) => {
                if ('active' in item && item.id === call.id) {
                    state.calls.unshift(item)
                    state.calls.splice(i+1, 1)
                }
            })
        }
    },
    [MUT_ASSISTANT_UPDATE_CALL_PARTICIPANTS]: (state, {id, participants}) => {
        let call = state.calls.find((call) => id === call.id)
        if (call) Vue.set(call, 'participants', participants)
    },
    [MUT_DELETE_CALL]: (state, id) => {
        let index = state.calls.findIndex(item => item.id === id)
        if(index > -1) state.calls.splice(index, 1)

        let indexId = state.transitingId.findIndex(item => item === id)
        if(indexId > -1) state.transitingId.splice(indexId, 1)
    },
    [MUT_ADD_TRANSIT_ID]: (state, ids) => {
        ids.forEach(id => state.transitingId.push(id))
    },
    [MUT_UPDATE_STATUS]: (state, data) => {
        state.status = data
    },
    [MUT_UPDATE_MICROPHONE]: (state, data) => {
        state.microphone = data
    },
    [MUT_UPDATE_SPEAKER]: (state, data) => {
        state.speaker = data
    },
    [MUT_UPDATE_DND]: (state, data) => {
        state.dnd = data
    },
    [MUT_CLEAR_DATA]: (state) => {
        state.calls = [],
        state.status = '',
        state.microphone = false,
        state.speaker = false,
        state.dnd = false,
        state.contact = {
            name: '',
            icon: ''
        },
        state.typeAssistant = '',
        state.activeNumber = ''
    },
    setTypeAssistant: (state, data) => {
        console.log("setTypeAssistant", data)
        state.typeAssistant = data
    },
    [MUT_SAVE_ASSISTANTS]: (state, data) => {
        state.assistantsList.splice(0, state.assistantsList.length)
        data.forEach((item) => {
            if ('s_number' in item) item.number = item.s_number
            switch (item.type) {
                case 'sip':
                    state.assistantsList.push({type: 'sipBooster', number: item.number})
                //case 'sipBooster':
                case 'dxBoosterPult':
                case 'dxBoosterAnalog':
                case 'mxBoosterPult':
                case 'mxBoosterAnalog':    
                    state.assistantsList.push(item)
                    break  
            }
        })
    },
    [MUT_SAVE_CONTACT]: (state, contact) => {
        Vue.set(state, 'contact', contact)
    },
    [MUT_SAVE_ACTIVE_NUMBER]: (state, number) => {
        state.activeNumber = number
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
