var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "focus", rawName: "v-focus" }],
      staticClass: "viewer",
      attrs: { tabindex: 1 },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
          ) {
            return null
          }
          $event.preventDefault()
          $event.stopPropagation()
          return (function() {})($event)
        },
        keyup: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k(
                $event.keyCode,
                "arrow-left",
                undefined,
                $event.key,
                undefined
              )
            ) {
              return null
            }
            return _vm.showPrevImg($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k(
                $event.keyCode,
                "arrow-right",
                undefined,
                $event.key,
                undefined
              )
            ) {
              return null
            }
            return _vm.showNextImg($event)
          }
        ]
      }
    },
    [
      _c("div", { staticClass: "header" }, [
        _vm.user
          ? _c(
              "div",
              { staticClass: "user" },
              [
                _c("icon-image", {
                  attrs: { src: _vm.user.photo, text: _vm.user.fio }
                }),
                _c("div", { staticClass: "user-info" }, [
                  _c("div", { staticClass: "fio" }, [
                    _vm._v(_vm._s(_vm.user.fio))
                  ]),
                  _c("div", { staticClass: "time" }, [
                    _vm._v(_vm._s(_vm.$t("has-sent") + _vm.time))
                  ])
                ])
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "btns" }, [
          _vm.pdfPages
            ? _c(
                "div",
                { staticClass: "pdf-block" },
                [
                  _c("custom-input-number", {
                    ref: "currentPage",
                    staticClass: "current-page",
                    attrs: {
                      blurOnApply: true,
                      default_text: _vm.pdfCurrentPage,
                      min: 1,
                      max: _vm.pdfPages
                    },
                    on: { apply: _vm.setCurrentPage }
                  }),
                  _c("custom-input-text", {
                    ref: "allPages",
                    staticClass: "all-pages",
                    attrs: {
                      default_text: _vm.allPagesInputText,
                      readonly: true
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "btns-block" }, [
            _vm.existBtns
              ? _c("i", {
                  staticClass: "fa fa-search-plus btn-zoom",
                  class: { disableBtn: _vm.zoomInDisable },
                  on: { click: _vm.zoomIn }
                })
              : _vm._e(),
            _vm.existBtns
              ? _c("i", {
                  staticClass: "fa fa-search-minus btn-zoom",
                  class: { disableBtn: _vm.zoomOutDisable },
                  on: { click: _vm.zoomOut }
                })
              : _vm._e(),
            _vm.existBtns
              ? _c("i", {
                  staticClass: "fal fa-retweet btn-turn",
                  on: { click: _vm.rotate }
                })
              : _vm._e(),
            !_vm.readOnly
              ? _c("i", {
                  staticClass: "fal fa-print btn-print",
                  on: { click: _vm.print }
                })
              : _vm._e(),
            _c("div", { staticClass: "download-link" }, [
              !_vm.readOnly
                ? _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.download($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-arrow-to-bottom download"
                      })
                    ]
                  )
                : _vm._e()
            ]),
            _c("i", {
              staticClass: "fal fa-times btn-close",
              on: { click: _vm.close }
            })
          ])
        ])
      ]),
      _c(
        "div",
        {
          ref: "content",
          staticClass: "body",
          class: { activeZoom: _vm.zoomed }
        },
        [
          _c(
            "div",
            {
              ref: "mediaa",
              staticClass: "slider-block",
              on: {
                mousedown: function($event) {
                  $event.preventDefault()
                  return _vm.onMouseDown($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "slide-arrow-back",
                  style: { visibility: _vm.canBack ? "visible" : "hidden" },
                  on: { click: _vm.showPrevImg }
                },
                [_c("i", { staticClass: "fal fa-angle-left" })]
              ),
              _c(
                "div",
                { staticClass: "mediaa", attrs: { id: "mediaContent" } },
                [
                  _c(
                    "transition",
                    [
                      _vm.preload
                        ? [
                            _c("div", { staticClass: "mediaa-content" }, [
                              _c("div", { staticClass: "mediaa-block" }, [
                                _vm.showSpinner
                                  ? _c("i", {
                                      staticClass: "fal fa-spinner fa-spin"
                                    })
                                  : _vm._e()
                              ])
                            ])
                          ]
                        : [
                            _vm.mediaType === "image"
                              ? _c("img", {
                                  ref: "img",
                                  staticClass: "photo",
                                  style: _vm.styleImg,
                                  attrs: { src: _vm.path },
                                  on: {
                                    contextmenu: _vm.onImageContextMenu,
                                    load: _vm.onImageLoaded,
                                    error: _vm.onError
                                  }
                                })
                              : _vm.mediaType === "pdf"
                              ? _c("img", {
                                  ref: "img",
                                  staticClass: "photo",
                                  style: _vm.styleImg,
                                  attrs: { src: _vm.pdfPath },
                                  on: {
                                    load: _vm.onImageLoaded,
                                    error: _vm.onError
                                  }
                                })
                              : _vm.mediaType === "video"
                              ? _c("custom-video", {
                                  attrs: {
                                    src: _vm.path,
                                    preload: "auto",
                                    muted: true,
                                    playsinline: true,
                                    loop: false,
                                    controls: true
                                  },
                                  on: {
                                    fullscreenchange: _vm.onFullScreenChange
                                  }
                                })
                              : _vm._e()
                          ]
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "slide-arrow-next",
                  style: { visibility: _vm.canForward ? "visible" : "hidden" },
                  on: { click: _vm.showNextImg }
                },
                [_c("i", { staticClass: "fal fa-angle-right" })]
              )
            ]
          ),
          _vm.subtitle
            ? _c(
                "div",
                {
                  staticClass: "subtitle",
                  style: _vm.multiple ? "bottom: 70px" : ""
                },
                [
                  _c("span", { staticClass: "scroll" }, [
                    _vm._v(_vm._s(_vm.subtitle))
                  ])
                ]
              )
            : _vm._e(),
          _vm.multiple
            ? _c("div", { staticClass: "slides-count-box" }, [
                _c(
                  "div",
                  { staticClass: "preview-block-wrapper" },
                  _vm._l(_vm.mediaItems, function(slide, idx) {
                    return _c(
                      "div",
                      {
                        staticClass: "preview-block",
                        class: { "active-slide": idx === _vm.currImgIndex }
                      },
                      [
                        _vm.mediaType === "image" || _vm.mediaType === "video"
                          ? _c("img", {
                              ref: "img",
                              refInFor: true,
                              staticClass: "photo",
                              attrs: { src: _vm.getPreviewByIndex(idx) },
                              on: {
                                click: function($event) {
                                  return _vm.clickedPreview(idx)
                                },
                                load: _vm.onImageLoaded,
                                error: _vm.onError
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "slides" },
                  _vm._l(_vm.mediaItems, function(slide, idx) {
                    return _c("div", {
                      staticClass: "slide",
                      class: { "active-slide": idx === _vm.currImgIndex }
                    })
                  }),
                  0
                )
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }