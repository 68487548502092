'use strict'

import {
    GET_ROOM_ID,
    GET_PROCESSINGS,
    GET_CONTACTS,
    GET_OPTIONS,
    GET_CREATOR_CONF,
    GET_ANSWER_STATUS,
    GET_TURN_SERVER,
    GET_DND,
    GET_HOLD_CONF,
    GET_COMPONENT_DATA,
    GET_LOCALSTREAM,
    GET_PEER_CONNECTION,
    IS_ADD_DIALOG_OPENED,
    IS_CONF_ROOM_FULL,
    GET_INCOMING_CALLS,
    GET_UID,
    GET_ACTIVE_WEBCAMERA,
    GET_ACTIVE_MICROPHONE,
    GET_ANSWERED_CALLS,
} from '../gettersTypes'
import {
    ACT_ADD_CONF,
    ACT_SAVE_REMOTE_PROCESSING,
    ACT_CONFERENCE_EVENT,
    ACT_SEND_OFFER_CONFERENCE,
    ACT_GET_TURN_SERVER,
    ACT_VIDEO_CONF_OPEN,
    ACT_CONFERENCE_RINGING,
    ACT_CONFERENCE_ANSVER,
    ACT_TERMINATION_EVENT,
    ACT_CONFERENCE_TERMINATION,
    ACT_SYNC_STORE_ELECTRON,
    ACT_MODAL_OPEN,
    ACT_CHANGE_CONTACT_STATUS,
    ACT_PHONE_HIDE,
    ACT_HOLD_CONF,
    ACT_ACTIVATE_CONF,
    ACT_SAVE_DATA_CONF,
    ACT_ADD_LOCALSTREAM,
    ACT_ADD_PEER_CONNECTION,
    ACT_DELETE_PEER_CONNECTION,
    ACT_DELETE_ANSWERED_CALL,
    ACT_TERMINATE_INCOMMING_CALL,
    ACT_CHANGE_CONFERENCE_EVENT,
    ACT_OPEN_ADD_DIALOG,
    ACT_SET_ADD_DIALOG_OPENED,
    ACT_ADD_CONTACTS_TO_CONFERENCE,
    ACT_UPDATE_INCOMMING_CALL,
    ACT_CONFERENCE_HANDLE_API_CONFERENCE_EVENT,
    ACT_ADD_INCOMMING_CALL,
} from '../actionsTypes'

import {
    MUT_SAVE_ROOM_ID,
    MUT_SAVE_REMOTE_PROCESSING,
    // MUT_SAVE_INVITING_CID,
    MUT_SAVE_CONTACTS,
    MUT_SAVE_OPTIONS,
    MUT_DELETE_CONTACT,
    MUT_DELETE_REMOTE_PROCESSING,
    MUT_SET_CREATOR_CONF,
    MUT_SET_ANSWER_STATUS,
    MUT_STATE_CLEAR,
    MUT_SAVE_TURNSERVER,
    MUT_ADD_CONTACT,
    MUT_SET_CONTACT,
    MUT_ADD_HOLDED_CONF,
    MUT_DELETE_HOLDED_CONF,
    MUT_STATE_CHANGE,
    MUT_SET_HOLD_CONF,
    MUT_SAVE_LOCALSTREAM,
    MUT_ADD_CONTACTS,
    MUT_ADD_PEER_CONNECTION,
    MUT_DELETE_PEER_CONNECTION,
    MUT_SET_ADD_DIALOG_OPENED,
} from '../mutationsTypes'

import {
    PHONE,
    MODAL,
    USERDATA,
    USERPROFILE,
    PHONE_CNTL,
} from '../modulesNames'

import { PHONE_TYPES, CONFERENCE_MEMBERS_MAX } from '../../constants'

import proto from '../../protocol'
import ipc from '../../../electron/ipc'

const state = {
    roomId: null,
    processings: [],
    invitingCid: null,
    contacts: [],
    options: {},
    isCreatorConf: false,
    answerStatus: false,
    turnServer: null,
    holdedConf: [],
    holdConf: false,
    localStream: null,
    peerConnections: [],
    addDialogOpened: false,
}

const getters = {
    [GET_ROOM_ID]: state => state.roomId,
    [GET_PROCESSINGS]: state => state.processings,
    [GET_CONTACTS]: state => state.contacts,
    [GET_OPTIONS]: (state) => state.options,
    [GET_CREATOR_CONF]: state => state.isCreatorConf,
    [GET_ANSWER_STATUS]: state => state.answerStatus,
    [GET_TURN_SERVER]: state => state.turnServer,
    [GET_HOLD_CONF]: state => state.holdConf,
    [GET_COMPONENT_DATA]: state => roomId => {
        let res = state.holdedConf.find(item => item.roomId === roomId)
        return res && res.componentData
    },
    [GET_LOCALSTREAM]: state => state.localStream,
    [GET_PEER_CONNECTION]: state => state.peerConnections,
    [IS_ADD_DIALOG_OPENED]: state => state.addDialogOpened,
    [IS_CONF_ROOM_FULL]: state => state.contacts.length >= CONFERENCE_MEMBERS_MAX,
}

const actions = {
    [ACT_ADD_CONF]: async ({ state, commit, dispatch, rootGetters }, data) => {
        let roomId = await proto.addConference(data)
        let contacts = data.contacts.map(item => {
            return { cid: item, status: 'ringing' }
        })
        let uid = rootGetters['userdata/getUid']
        contacts.unshift({ cid: uid, status: 'joined' })
        dispatch(ACT_GET_TURN_SERVER)
        commit(MUT_SAVE_ROOM_ID, { roomId })
        const activeMicrophone = rootGetters[`${USERDATA}/${GET_ACTIVE_MICROPHONE}`]
        if (activeMicrophone && activeMicrophone.deviceId && activeMicrophone.deviceId !== 'default')
            data.options.audio = { deviceId: { exact: activeMicrophone.deviceId } }
        const activeCamera = rootGetters[`${USERDATA}/${GET_ACTIVE_WEBCAMERA}`]
        if (data.options.video && activeCamera && activeCamera.deviceId && activeCamera.deviceId !== 'default') 
            data.options.video =  { deviceId: { exact: activeCamera.deviceId } }
        commit(MUT_SAVE_OPTIONS, { options: data.options })
        commit(MUT_SET_CREATOR_CONF, { value: true })
        commit(MUT_SAVE_CONTACTS, { contacts: checkLocalContact(contacts, uid) })       
        dispatch(ACT_SYNC_STORE_ELECTRON)
        return roomId
    },
    [ACT_SEND_OFFER_CONFERENCE]: ({ state }, data) => {
        data.candidates = JSON.parse(data.candidates)
        proto.conferenceProcessing(data)
    },
    [ACT_SAVE_REMOTE_PROCESSING]: ({ state, commit }, data) => {
        commit(MUT_SAVE_REMOTE_PROCESSING, { data })
    },
    [ACT_CONFERENCE_EVENT]: ({ state, dispatch, commit, rootGetters }, data) => {
        let dnd = rootGetters[`${USERPROFILE}/${GET_DND}`]
        if(dnd) return

        if (typeof state.roomId === 'number' && data.roomId !== state.roomId) return
        let uid = rootGetters['userdata/getUid']
        dispatch(ACT_GET_TURN_SERVER)
        commit(MUT_SAVE_ROOM_ID, { roomId: data.roomId })
        commit(MUT_SAVE_OPTIONS, { options: data.options })
        commit(MUT_SAVE_CONTACTS, { contacts: checkLocalContact(data.contacts, uid) })
        
        dispatch(ACT_CONFERENCE_RINGING)
        dispatch(`${PHONE}/${ACT_VIDEO_CONF_OPEN}`, { 
            data: { invitation: true, cid: data.cid },
            countUsers: data.contacts.length,
            video: data.options.video
        }, { root: true })
        dispatch(ACT_SYNC_STORE_ELECTRON)

        commit(MUT_SET_ANSWER_STATUS, { value: true })
        proto.conferenceAnsver({ roomId: state.roomId })
    },
    [ACT_CHANGE_CONFERENCE_EVENT]: ({ state, commit }, data) => {
        data.contacts.forEach(contact => {
            let contactExsists = state.contacts.some(item => item.cid === contact.cid)
            if (!contactExsists) commit(MUT_ADD_CONTACT, { contact })  
        })
    },
    [ACT_TERMINATION_EVENT]: ({ state, commit, dispatch, rootGetters }, data) => {
        if (!state.roomId) {
            let uid = rootGetters[`${USERDATA}/${GET_UID}`]
            let termData = {
                type: PHONE_TYPES.CONFERENCE,
                id: data.roomId,
                remoteTerminate: true
            }
            if (data.cid === uid) {
                dispatch(`${PHONE_CNTL}/${ACT_TERMINATE_INCOMMING_CALL}`, termData, {root: true})
            } else {
                let incomeCalls = rootGetters[`${PHONE_CNTL}/${GET_INCOMING_CALLS}`]({type: PHONE_TYPES.CONFERENCE,  id: data.roomId})
                let incomeCall = incomeCalls.shift()
                if (incomeCall && incomeCall.data && incomeCall.data.contacts) {
                    incomeCall = { ...incomeCall }
                    let cidIndex = incomeCall.data.contacts.findIndex(({cid}) => cid === data.cid)
                    if (cidIndex >= 0) {
                        incomeCall.data.contacts.splice(cidIndex, 1)
                        if (incomeCall.data.contacts.length > 1) {
                            dispatch(`${PHONE_CNTL}/${ACT_UPDATE_INCOMMING_CALL}`, incomeCall, {root: true})
                        } else {
                            dispatch(`${PHONE_CNTL}/${ACT_TERMINATE_INCOMMING_CALL}`, termData, {root: true})
                        }
                    }
                }
            }
        }
        if (state.roomId === data.roomId) {
            commit(MUT_DELETE_CONTACT, { cid: data.cid })
            commit(MUT_DELETE_REMOTE_PROCESSING, { cid: data.cid })
            if(data.reason === 'local' || ((!state.isCreatorConf && !state.answerStatus) && data.reason === 'timeout') || (!state.answerStatus && !state.processings.length)) {
                commit(MUT_STATE_CLEAR)                
                ipc.send('sync-store-phone', { command: 'ACT_PHONE_HIDE' })
            }
        }
    },
    [ACT_GET_TURN_SERVER]: async ({ state, commit }) => {
        let turnServer = await proto.getTurnServer()
        commit(MUT_SAVE_TURNSERVER, { turnServer })
    },
    [ACT_CONFERENCE_RINGING]: ({ state }) => {
        proto.conferenceRinging({ roomId: state.roomId })
    },
    [ACT_CONFERENCE_TERMINATION]: ({ state, commit, dispatch }, roomId) => {
        proto.conferenceTermination({ roomId })
        commit(MUT_STATE_CLEAR)
        dispatch(`${PHONE_CNTL}/${ACT_DELETE_ANSWERED_CALL}`, { type: PHONE_TYPES.CONFERENCE, id: roomId }, { root: true })
    },
    [ACT_SYNC_STORE_ELECTRON]: ({ state, commit }) => {
        ipc.send('sync-store-phone', { command: 'ACT_SYNC_STORE_ELECTRON', data: state })
    },
    [ACT_OPEN_ADD_DIALOG]: ({ dispatch} ) => {
        dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, { name: 'AddToConf' }, {root: true})
    },
    [ACT_SET_ADD_DIALOG_OPENED]: ({ commit }, opened) => {
        commit(MUT_SET_ADD_DIALOG_OPENED, opened)
    },
    [ACT_ADD_CONTACTS_TO_CONFERENCE]: async ({ commit }, contacts) => {
        if (!contacts.length) return
        let res = await proto.ConferenceAddUser({ roomId: state.roomId, contacts })
        if (res) commit(MUT_ADD_CONTACTS, contacts.map((cid) => ({cid, status: 'ringing'})))
    },
    [ACT_CHANGE_CONTACT_STATUS]: ({ state, commit, dispatch, rootGetters }, data) => {
        if (state.roomId === data.roomId) commit(MUT_SET_CONTACT, { data })
        else {
            let incomeCalls = rootGetters[`${PHONE_CNTL}/${GET_INCOMING_CALLS}`]({type: PHONE_TYPES.CONFERENCE,  id: data.roomId})
            let incomeCall = incomeCalls.shift()
            if (incomeCall && incomeCall.data && incomeCall.data.contacts) {
                incomeCall = { ...incomeCall }
                if (updateContactsStatus(incomeCall.data.contacts, data)) {
                    dispatch(`${PHONE_CNTL}/${ACT_UPDATE_INCOMMING_CALL}`, incomeCall, { root: true })
                }
            }
        }
    },
    [ACT_CONFERENCE_HANDLE_API_CONFERENCE_EVENT]: ({ state, commit, dispatch, rootGetters }, data) => {

        let answeredCalls = rootGetters[`${PHONE_CNTL}/${GET_ANSWERED_CALLS}`]
        let incomingCalls = rootGetters[`${PHONE_CNTL}/${GET_INCOMING_CALLS}`]
        let confExistsInAnsweredCalls = answeredCalls.some(item => item.id === data.roomId)
        let confExistsInIncommingCalls = incomingCalls.some(item => item.id === data.roomId)
        if (confExistsInAnsweredCalls) {
            dispatch(ACT_CHANGE_CONFERENCE_EVENT, data)
            return
        } else if (confExistsInIncommingCalls) {
            commit(`${PHONE_CNTL}/mutUpdateIncommingCall`, { data, type: PHONE_TYPES.CONFERENCE, id: data.roomId }, { root: true })
            return
        } else if (answeredCalls.length) return

        dispatch(`${PHONE_CNTL}/${ACT_ADD_INCOMMING_CALL}`, {
            type: PHONE_TYPES.CONFERENCE,
            id: data.roomId,
            audio: true,
            video: Boolean(data.options.video),
            data
        }, { root: true })
    },
    [ACT_HOLD_CONF]: ({ state, commit }) => {
        commit(MUT_SET_HOLD_CONF, { value: true })
        
    },
    [ACT_SAVE_DATA_CONF]: ({ state, commit }, data) => {
        // commit(MUT_ADD_HOLDED_CONF, { data })
        commit(MUT_STATE_CLEAR)
    },
    [ACT_ACTIVATE_CONF]: ({ commit }, roomId) => {
        commit(MUT_STATE_CHANGE, { roomId })
        // commit(MUT_DELETE_HOLDED_CONF, { roomId })
    },
    [ACT_ADD_LOCALSTREAM]: ({ state, commit }, stream) => {
        // commit(MUT_SAVE_LOCALSTREAM, stream)
    },
    [ACT_ADD_LOCALSTREAM]: ({ state, commit }, stream) => {
        commit(MUT_SAVE_LOCALSTREAM, stream)
        // ipc.send('sync-actions', { command: 'ACT_ADD_LOCALSTREAM', stream })
    },
    [ACT_ADD_PEER_CONNECTION]: ({ commit }, pc) => {
        commit(MUT_ADD_PEER_CONNECTION, pc)
    },
    [ACT_DELETE_PEER_CONNECTION]: ({ commit }, cid) => {
        commit(MUT_DELETE_PEER_CONNECTION, cid)
    }
}

const mutations = {
    [MUT_SAVE_ROOM_ID]: (state, { roomId }) => {
        console.log('roomId', roomId)
        state.roomId = roomId
        ipc.send('sync-store-phone', { command: 'MUT_SAVE_ROOM_ID', data: { roomId }})
    },
    [MUT_SAVE_CONTACTS]: (state, { contacts }) => {
        state.contacts = contacts
        ipc.send('sync-store-phone', { command: 'MUT_SAVE_CONTACTS', data: { contacts }})
        //let size = calculateSizeWindow(state.contacts.length, state.options.video, state.isCreatorConf)
        //ipc.send('set-phone-size', { height: size.height, width: size.width })
    },
    [MUT_SET_CONTACT]: (state, { data }) => {
        console.log('MUT_SET_CONTACT')
        updateContactsStatus(state.contacts, data)
    },
    [MUT_ADD_CONTACT]: (state, { contact }) => {
        console.log('contactcontact', contact)
        state.contacts.push(contact)
        ipc.send('sync-store-phone', { command: 'MUT_ADD_CONTACT', data: { contact }})
        //let size = calculateSizeWindow(state.contacts.length, state.options.video, state.isCreatorConf)
        //ipc.send('set-phone-size', { height: size.height, width: size.width })
        
    },
    [MUT_ADD_CONTACTS]: (state, contacts) => {
        state.contacts.push(...contacts)
        //let size = calculateSizeWindow(state.contacts.length, state.options.video, state.isCreatorConf)
        //ipc.send('set-phone-size', { height: size.height, width: size.width })
    },
    [MUT_DELETE_CONTACT]: (state, { cid }) => {
        let index = state.contacts.findIndex(item => item.cid === cid)
        if (index > -1) {
            state.contacts.splice(index, 1)
            ipc.send('sync-store-phone', { command: 'MUT_DELETE_CONTACT', data: { cid }})
        }
        //let size = calculateSizeWindow(state.contacts.length, state.options.video, state.isCreatorConf)
        //ipc.send('set-phone-size', { height: size.height, width: size.width })
    },
    [MUT_SAVE_OPTIONS]: (state, { options }) => {
        state.options = options
        ipc.send('sync-store-phone', { command: 'MUT_SAVE_OPTIONS', data: { options }})
    },
    [MUT_SAVE_REMOTE_PROCESSING]: (state, { data }) => {
        state.processings.push(data)
        ipc.send('sync-store-phone', { command: 'MUT_SAVE_REMOTE_PROCESSING', data: { data }})
    },
    [MUT_DELETE_REMOTE_PROCESSING]: (state, { cid }) => {
        let index = state.processings.findIndex(item => item.cid === cid)
        if (index > -1) {
            state.processings.splice(index, 1)
            ipc.send('sync-store-phone', { command: 'MUT_DELETE_REMOTE_PROCESSING', data: { cid }})
        }
    },
    [MUT_SET_CREATOR_CONF]: (state, { value }) => {
        state.isCreatorConf = value
        ipc.send('sync-store-phone', { command: 'MUT_SET_CREATOR_CONF', data: { value }})
    },
    [MUT_SET_ANSWER_STATUS]: (state, { value }) => {
        state.answerStatus = value
        ipc.send('sync-store-phone', { command: 'MUT_SET_ANSWER_STATUS', data: { value }})
    },
    [MUT_SAVE_TURNSERVER]: (state, { turnServer }) => {
        console.log('MUT_SAVE_TURNSERVER')
        console.log('turnServer', turnServer)
        state.turnServer = turnServer
        ipc.send('sync-store-phone', { command: 'MUT_SAVE_TURNSERVER', data: { turnServer }})
    },
    [MUT_STATE_CLEAR]: (state) => {
        state.roomId = null
        state.processings = []
        state.invitingCid = null
        state.contacts = []
        state.options = {}
        state.isCreatorConf = false
        state.answerStatus = false
        state.addDialogOpened = false
        // state.holdConf = false
        // state.turnServer = null
        console.log('MUT_STATE_CLEAR', state)
        ipc.send('sync-store-phone', { command: 'MUT_STATE_CLEAR'})
    },
    [MUT_STATE_CHANGE]: (state, { roomId }) => {
        state.holdedConf.forEach((item, i) => {
            if (item.roomId === roomId) {
                state.roomId = item.roomId                
                state.invitingCid = item.invitingCid                
                state.options = item.options
                state.isCreatorConf = item.isCreatorConf
                state.answerStatus = item.answerStatus
                state.processings = item.processings
                state.contacts = item.contacts
            }
        })
        ipc.send('sync-store-phone', { command: 'MUT_STATE_CHANGE', data: { roomId } })
    },
    [MUT_ADD_HOLDED_CONF]: (state, { data }) => {
        state.holdedConf.push({
            roomId: state.roomId,
            processings: state.processings,
            invitingCid: state.invitingCid,
            contacts: state.contacts,
            options: state.options,
            isCreatorConf: state.isCreatorConf,
            answerStatus: state.answerStatus,
            componentData: data
            // turnServer: state.turnServer
        })
        console.log('MUT_ADD_HOLDED_CONF', state)
        ipc.send('sync-store-phone', { command: 'MUT_ADD_HOLDED_CONF', data: { data } })
    },
    [MUT_DELETE_HOLDED_CONF]: (state, { roomId }) => {
        state.holdedConf.forEach((item, i) => {
            if (item.roomId === roomId) state.holdedConf.splice(i, 1)
        })
        ipc.send('sync-store-phone', { command: 'MUT_DELETE_HOLDED_CONF', data: { roomId } })
    },
    [MUT_SET_HOLD_CONF]: (state, { value }) => {
        state.holdConf = value
        ipc.send('sync-store-phone', { command: 'MUT_SET_HOLD_CONF', data: { value } })
    },
    [MUT_SAVE_LOCALSTREAM]: (state, stream) => {
        state.localStream = stream
        ipc.send('sync-store-phone', { command: 'MUT_SAVE_LOCALSTREAM', data: stream })
    },
    [MUT_ADD_PEER_CONNECTION]: (state, pc) => {
        state.peerConnections.push(pc)
    },
    [MUT_DELETE_PEER_CONNECTION]: (state, cid) => {
        state.peerConnections.forEach((item, i) => {
            if (item.cid === cid) state.peerConnections.splice(i, 1)
        })
    },
    [MUT_SET_ADD_DIALOG_OPENED]: (state, status) => {
        state.addDialogOpened = status
    },
}

function updateContactsStatus(contacts = [], data) {
    let changed = false
    contacts.forEach(contact => {
        if (contact.cid === data.cid && contact.status !== data.status) {
            contact.status = data.status
            changed = true
        }
    })
    if (changed) ipc.send('sync-store-phone', { command: 'MUT_SET_CONTACT', data: { data } })
    return changed
}

function checkLocalContact(contacts, uid) {
    contacts.forEach((contact, i) => {
        if (contact.cid === uid && i !== 0) {
            contacts.splice(i, 1)
            contacts.unshift(contact)
        }
    })
    return contacts
}

function calculateSizeWindow(countUsers, video, isCreatorConf) {
    if (!video) return { width: 344, height: 544 }
    switch (countUsers) {
        case 1:
        case 2:
            return { width: 444, height: 644 }
            break
        case 3:
        case 4:
            return { width: 844, height: 644 }
            break
        case 5:
        case 6:
            return { width: 1244, height: 644 }
            break
        default:
            return {}    
    }  
} 

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
