var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "content-wrapper" },
          [
            _c("p", [_vm._v(_vm._s(_vm.text))]),
            !_vm.isNote && _vm.ownMsg
              ? _c("custom-radio", {
                  staticClass: "custom-radio",
                  attrs: {
                    value_change: _vm.forme,
                    inputs: _vm.radioItems,
                    default_data: _vm.forme
                  },
                  on: {
                    "update:value_change": function($event) {
                      _vm.forme = $event
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.onCancel } }, [
          _vm._v(_vm._s(_vm.$t("cancel")))
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.onOk } }, [
          _vm._v(_vm._s(_vm.$t("loginPage.ok")))
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }