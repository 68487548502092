var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-info" },
    [
      _c(
        "info-main-block",
        {
          staticClass: "contact-main-block",
          attrs: {
            "icon-text": _vm.fio,
            "common-text": _vm.fio,
            "additional-text": _vm.status,
            official: false,
            isAllowedToViewContact: true,
            edit: false,
            "icon-type": "contact",
            "icon-id": _vm.ssi
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "additional-text",
              attrs: { slot: "after-common-text" },
              slot: "after-common-text"
            },
            [_c("p", [_vm._v(_vm._s(_vm.$t("tetra-subscriber")))])]
          ),
          _c(
            "div",
            {
              staticClass: "contact-btns",
              attrs: { slot: "after-additional-text" },
              slot: "after-additional-text"
            },
            [
              _c(
                "info-icon",
                {
                  key: "1",
                  staticClass: "main-btn",
                  attrs: {
                    title: _vm.$t("information.message"),
                    background: true,
                    link: true
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.sendSDS($event)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-comment i-special",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              ),
              _c(
                "info-icon",
                {
                  key: "2",
                  staticClass: "main-btn",
                  attrs: {
                    title: _vm.$t("tetra-call"),
                    background: true,
                    link: true
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.tetraCall($event)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-phone",
                    attrs: { slot: "default" },
                    slot: "default"
                  })
                ]
              )
            ],
            1
          )
        ]
      ),
      [
        _c(
          "actions-container",
          [
            [
              _c("list-item", {
                attrs: {
                  slot: "action",
                  title: "SSI",
                  footer: _vm.ssi,
                  chevron: false
                },
                slot: "action"
              })
            ]
          ],
          2
        ),
        _vm.note
          ? _c(
              "actions-container",
              [
                [
                  _c("list-item", {
                    attrs: {
                      slot: "action",
                      title: _vm.$t("tetra-note"),
                      footer: _vm.note,
                      chevron: false
                    },
                    slot: "action"
                  })
                ]
              ],
              2
            )
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }