var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-wrapper" }, [
    _c("div", { staticClass: "search-filter" }, [
      _c(
        "div",
        { staticClass: "filter-btn", on: { click: _vm.openSearchFilter } },
        [
          _c("i", {
            staticClass: "fa fa-sliders",
            attrs: { "aria-hidden": "true" }
          })
        ]
      ),
      _c("div", { staticClass: "title", on: { click: _vm.openSearchFilter } }, [
        _vm._v(_vm._s(_vm.$t("search-comp.search-more")))
      ])
    ]),
    _c(
      "div",
      { staticClass: "select-contact" },
      [
        _vm.selecter
          ? _c("selected-items-line", {
              attrs: { selected: _vm.selectedItemsLine },
              on: { remove: _vm.unselectContact }
            })
          : _vm._e(),
        _c("recycle-contacts-list", {
          ref: "scroller",
          attrs: { contactsList: _vm.contactsList },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var item = ref.item
                return [
                  !_vm.isExcluded(item)
                    ? _c("contacts-list-item", {
                        attrs: {
                          contact: item,
                          noswitch: true,
                          isShowPhoto: _vm.isContactPhoto,
                          marked: _vm.isMarked(item),
                          noSelection: true
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.selectContact(item)
                          }
                        }
                      })
                    : _c("contacts-list-item", {
                        staticClass: "disabled",
                        attrs: {
                          contact: item,
                          noswitch: true,
                          marked: true,
                          noSelection: true
                        }
                      }),
                  _c("div", { staticClass: "hor-line" })
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }