var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "wrapper-item" }, [
            _c("div", { staticClass: "item-header" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("settings.title-text-size")))])
            ]),
            _c("div", { staticClass: "item-content" }, [
              _c(
                "div",
                { staticClass: "range-slider" },
                [
                  _c("vue-slider", {
                    key: "notif",
                    staticClass: "slider",
                    attrs: {
                      disabled: _vm.sliderDisabled,
                      min: "0",
                      max: "100",
                      step: "10"
                    },
                    model: {
                      value: _vm.titleFontSizeSliderValue,
                      callback: function($$v) {
                        _vm.titleFontSizeSliderValue = $$v
                      },
                      expression: "titleFontSizeSliderValue"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "wrapper-item" }, [
            _c("div", { staticClass: "item-header" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("settings.subtitle-text-size")))
              ])
            ]),
            _c("div", { staticClass: "item-content" }, [
              _c(
                "div",
                { staticClass: "range-slider" },
                [
                  _c("vue-slider", {
                    key: "notif",
                    staticClass: "slider",
                    attrs: {
                      disabled: _vm.sliderDisabled,
                      min: "0",
                      max: "100",
                      step: "10"
                    },
                    model: {
                      value: _vm.subTitleFontSizeSliderValue,
                      callback: function($$v) {
                        _vm.subTitleFontSizeSliderValue = $$v
                      },
                      expression: "subTitleFontSizeSliderValue"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "wrapper-item" }, [
            _c("div", { staticClass: "item-header" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("settings.messages-text-size")))
              ])
            ]),
            _c("div", { staticClass: "item-content" }, [
              _c(
                "div",
                { staticClass: "range-slider" },
                [
                  _c("vue-slider", {
                    key: "notif",
                    staticClass: "slider",
                    attrs: {
                      disabled: _vm.sliderDisabled,
                      min: "0",
                      max: "100",
                      step: "10"
                    },
                    model: {
                      value: _vm.messageFontSizeSliderValue,
                      callback: function($$v) {
                        _vm.messageFontSizeSliderValue = $$v
                      },
                      expression: "messageFontSizeSliderValue"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "wrapper-item" }, [
            _c("div", { staticClass: "item-header" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("settings.text-contrast")))])
            ]),
            _c("div", { staticClass: "item-content" }, [
              _c(
                "div",
                { staticClass: "range-slider" },
                [
                  _c("vue-slider", {
                    key: "notif",
                    staticClass: "slider",
                    attrs: {
                      disabled: _vm.sliderDisabled,
                      min: "0",
                      max: "100",
                      step: "10"
                    },
                    model: {
                      value: _vm.fontContrastSliderValue,
                      callback: function($$v) {
                        _vm.fontContrastSliderValue = $$v
                      },
                      expression: "fontContrastSliderValue"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "wrapper-item examples" }, [
            _c("div", { staticClass: "item-content titles" }, [
              _c("div", { staticClass: "icon-example" }, [
                _c("img", {
                  staticClass: "photo",
                  attrs: {
                    src: _vm.noteImg,
                    alt: "",
                    ondrag: "return false",
                    ondragdrop: "return false",
                    ondragstart: "return false"
                  }
                })
              ]),
              _c("div", { staticClass: "title-example" }, [
                _c("div", { staticClass: "title", style: _vm.fontsStyles }, [
                  _vm._v(_vm._s(_vm.$t("settings.text-title")))
                ]),
                _c("div", { staticClass: "subtitle", style: _vm.fontsStyles }, [
                  _vm._v(_vm._s(_vm.$t("settings.text-subtitle")))
                ])
              ])
            ]),
            _c("div", { staticClass: "item-content chat-text-example" }, [
              _c("span", { style: _vm.fontsStyles }, [
                _vm._v(_vm._s(_vm.$t("settings.text-message")))
              ]),
              _c("div", { staticClass: "message-footer" }, [_vm._v("12:00")])
            ])
          ])
        ])
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass: "btn reset",
            attrs: { title: _vm.$t("reset-initial") },
            on: {
              click: function($event) {
                return _vm.reset()
              }
            }
          },
          [_c("i", { staticClass: "fas fa-undo-alt" })]
        ),
        _c("div", { staticClass: "btns-right" }, [
          _c(
            "div",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  return _vm.modalClose()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c("div", { staticClass: "btn", on: { click: _vm.okBtn } }, [
            _vm._v(_vm._s(_vm.$t("apply")))
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }