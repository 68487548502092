export const LOGIN = 'login';
export const SOCKET = 'socket';
export const CONTACTS = 'contacts';
export const CHATS = 'chats';
export const CHAT = 'chat';
export const BLOB = 'blob';
export const AJAX = 'ajax';
export const CHANNELS = 'channels';
export const CHANNEL = 'channel';
export const USERDATA = 'userdata';
export const ACS = 'acs';
export const CALLS = 'calls';
export const CONTENT_MANAGER = 'content_manager';
export const NOTIFICATIONS = 'notifications';
export const INFO = 'info';
export const ALARM = 'alarm';
export const BOTS = 'bots';
export const PHONE = 'phone';
export const LOCATIONS = 'locations';
export const CLIENTDATA = 'clientdata';
export const MODAL = 'modal';
export const RADIO = 'radio';
export const TETRA = 'tetra_radio';
export const RC_RADIO = 'rc_radio';
export const VIDEO_CONF = 'videoconf';
export const ASISTANTS = 'asistants';
export const PHONE_CNTL = 'phone_cntl';
export const USERPROFILE = 'userprofile';
export const MEDIADEVICES = 'mediadevices';
export const VIDEO_CONF_NEW = 'video_conf_new';
export const VM_CONF = 'vm_conf';
export const ROSCHAT_CONF = 'roschat_conf';
export const ELECTRON = 'electron';
export const DLP = 'dlp';
export const DATA_MESSAGE_BUILDER = 'data_message_builder';
export const INTEGRATIONS = 'integrations'
