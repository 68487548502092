//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalBtn from "./ModalBtn.vue"
import CustomSearch from "../custom/CustomSearch.vue"
import CustomTabs from "../custom/CustomTabs.vue"
import SelectContactBody from "./SelectContactBody.vue"
import modal_mixin from "./modal-mixin"
import {CONTACTS_SEARCH_TYPES} from '../../constants'

export default {
    name: "SelectContact",
    components: { ModalBtn, SelectContactBody, CustomSearch, CustomTabs },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 350,
                pivotY: 0.1,
                width: 400,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 300px;"                    
            },
            selected: [],
            search: '',
            tabs: [{ key: 0, value: this.$t('chat.general-contacts') }, { key: 1, value: this.$t('chat.personal-contacts')}],
            showContactsTabs: false,
            isLocalContacts: false,
            searchType: CONTACTS_SEARCH_TYPES.VIEW_CONTACT
        }
    },
    beforeDestroy() {
        this.props.cancel && this.props.cancel()
    },
    computed: {
        botsIsVisible() { return false },
        cancelText() { return this.props.cancelText ||  this.$t('cancel') },
        okText() { return this.props.okText || this.$t('loginPage.ok') },
        multiple() { return !!this.props.multiple },
        title() { return this.multiple ? this.$t('chat.contacts-selection') : this.$t('chat.contact-selection') },
        excludeMembers() { 
            return this.props.excludeMembers || [] 
        }
    },
    watch: {
        selected() {
            this.maxHeight = this.selected.length !== 0;
        }
    },
    methods: {
        onOk(contact) {
            this.modalClose()
            this.props.cb && this.props.cb(contact)
        },
        onContactSelected(contact) {
            if (!this.multiple) this.onOk(contact)
            else this.selected.push(contact)
            this.$refs.clearSearch && this.$refs.clearSearch.clearSearch()
            this.$refs.clearSearch && this.$refs.clearSearch.$el.querySelector('input').focus()
        },
        onContactUnselected(contact) {
            this.selected.splice(this.selected.indexOf(contact), 1)
        },
    }
}

