
import { PTT_STATUS } from "./PttButton.vue"
import {mapGetters, mapActions} from 'vuex'
import {RADIO, TETRA} from '../../store/modulesNames'
import {
    GET_TETRA_CONNECTION_PARAMS,
    GET_TETRA_CONNECT_STATUS,
    GET_TETRA_CONNECT_ERROR,
    GET_TETRA_LINK_STATUS,
    GET_TETRA_CELL,
    GET_TETRA_GROUPS,
    GET_TETRA_ACTIVE_GROUP,
    GET_TETRA_PTT_PUSHED,
    GET_TETRA_CALL,
    GET_CURRENT_RADIO,
    GET_TETRA_ECHO,
    GET_SPEECH_PACK_RATE,
    GET_TETRA_UNWATCHED_MESSAGES_COUNT,
    GET_TETRA_USERS_LIST,
} from '../../store/gettersTypes'
import {
    ACT_TETRA_CONNECT,
    ACT_TETRA_DISCONNECT,
    ACT_TETRA_PUSH_PTT,
    ACT_TETRA_POP_PTT,
    ACT_TETRA_EXIT_BTN,
    ACT_TETRA_SET_ECHO,
    ACT_TETRA_UPDATE_USERS_LIST,
} from '../../store/actionsTypes'

import {CONNECT_STATUS, CONNECT_ERROR, LINK_STATUS, CALL_EVENTS, RELEASE_CAUSE, TX_GRANT} from '../../store/modules/tetra-radio'
import RadioComponent from "./RadioComponent.vue"

import modal_mixin from '../modal/modal-mixin'
import SearchTetraContacts from "./SearchTetraContacts.vue"
import TetraChat from "./TetraChat.vue"

export default {
    name: "TetraRadio",
    extends: RadioComponent,
    components: {
        SearchTetraContacts,
        TetraChat,
    },
    mixins: [modal_mixin],
    computed: {
        connected() {
            return this[GET_TETRA_CONNECT_STATUS] !== CONNECT_STATUS.OFFLINE && this.linkOpened
        },
        title() {
            return this.texts.title
        },
        subTitle() {
            return this.texts.subTitle
        },
        infoText() {
            let text = ''
            if (this.cell) text+=`${this.$t('base')}${this.cell}`
            if (this[GET_SPEECH_PACK_RATE]) text+=`${text ? ', ' : ''} ${this.$t('pack/sec')}${this[GET_SPEECH_PACK_RATE]}`
            return text
        },
        ssi() {
            return this.currentRadio.ssi
        },
        name() {
            return this.currentRadio.name
        },
        linkOpened() {
            return this[GET_TETRA_LINK_STATUS] === LINK_STATUS.OPENED
        },
        cell() {
          return this[GET_TETRA_CELL]
        },
        pttPushed() {
            return this[GET_TETRA_PTT_PUSHED]
        },
        pttStatus() {
            let status = PTT_STATUS.IDLE
            let {callEvent, txGrant} = (this[GET_TETRA_CALL] || {})
            const pttPushed = this.pttPushed
            switch (callEvent) {
                case CALL_EVENTS.STARTING:
                    status = pttPushed ? PTT_STATUS.REQUEST : PTT_STATUS.IDLE
                    break
                case CALL_EVENTS.RELEASE:
                    status = pttPushed ? PTT_STATUS.REQUEST : PTT_STATUS.IDLE
                    break
                case CALL_EVENTS.TX_GRANT:
                    switch (txGrant) {
                        case TX_GRANT.GRANTED:
                            status = PTT_STATUS.PUSHED
                            break
                        case TX_GRANT.NOT_GRANTED:
                            status = PTT_STATUS.REQUEST
                            break
                        case TX_GRANT.REQUEST_QUEUED:
                            status = PTT_STATUS.QUEUED
                            break
                        case TX_GRANT.TO_ANOTHER_USER:
                            status = PTT_STATUS.PUSHED_OTHER
                            break
                    }
                    break
                case CALL_EVENTS.TX_INTERRUPT:
                    status = pttPushed ? PTT_STATUS.REQUEST : PTT_STATUS.PUSHED_OTHER
                    break
                case CALL_EVENTS.CEASING:
                    status = pttPushed ? PTT_STATUS.REQUEST : PTT_STATUS.CEASING
                    break
            }
            return status
        },
        texts () {
            if (this[GET_TETRA_CALL]) return this.getCallStatusTexts()
            else if (this.connected) return this.getActiveTexts()
            else return this.getConnectionTexts()
        },
        missedMsgsCount() {
            return this[GET_TETRA_UNWATCHED_MESSAGES_COUNT]
        },
        ...mapGetters(RADIO, {currentRadio: GET_CURRENT_RADIO, [GET_SPEECH_PACK_RATE]: GET_SPEECH_PACK_RATE}),
        ...mapGetters(TETRA, [
            GET_TETRA_CONNECTION_PARAMS, GET_TETRA_CONNECT_STATUS, 
            GET_TETRA_CONNECT_ERROR, GET_TETRA_LINK_STATUS, 
            GET_TETRA_CELL, GET_TETRA_CALL, GET_TETRA_GROUPS, 
            GET_TETRA_ACTIVE_GROUP, GET_TETRA_PTT_PUSHED, 
            GET_TETRA_ECHO, GET_TETRA_UNWATCHED_MESSAGES_COUNT,
            GET_TETRA_USERS_LIST,
        ])
    },
    mounted() {
        this.$bus.$on('open-tetra-chat', (data) => {
            let contact = { ssi: data.params.ssi, fio: data.params.name }
            this.modalOpen({
                name: 'tetra-chat',
                component: TetraChat,
                props: { contact }
            }) 
        })
    },
    watch: {
        connected(val) {
            if (val) {
                const contactList = this[GET_TETRA_USERS_LIST]('contact') || []
                const contactListSSIs = contactList.map(c => c.ssi)
                const msgList = this[GET_TETRA_USERS_LIST]('msg') || []
                const msgListSSIs = msgList.map(m => m.ssi)
                const aggListSSIs = [...new Set([...contactListSSIs, ...msgListSSIs])]
                this[ACT_TETRA_UPDATE_USERS_LIST]({list: aggListSSIs, type: 'init'})
            }
        },
    },
    methods: {
        onRepeatLast() {
            this[ACT_TETRA_SET_ECHO](!this[GET_TETRA_ECHO])
        },
        onGroupSelect(e) {
            let handlers = []
            handlers.push({ item_name: this.$t('select-group'), handler: this.chooseGroup })
            handlers.push({ item_name: this.$t('contacts'), handler: this.searchContacts })
            this.cmOpen(e, handlers, 'right-bottom')
        },
        getConnectionTexts() {
            let title = ''
            let subTitle = ''
            switch (this[GET_TETRA_CONNECT_STATUS]) {
                case CONNECT_STATUS.OFFLINE:
                    title = this.$t('radio-off')
                    break
                case CONNECT_STATUS.CONNECTING:
                    title = this.$t('turning-on-radio')
                    break
                case CONNECT_STATUS.READY:
                    title = this.$t('initializing-radio')
                    switch (this[GET_TETRA_LINK_STATUS]) {
                        case LINK_STATUS.DISCONNECTED:
                            subTitle = this.$t('no-tetra-conn')
                            break
                        case LINK_STATUS.CREATING:
                            subTitle = this.$t('opening-tetra-channel')
                            break
                        case LINK_STATUS.CLOSED:
                            subTitle = this.$t('tetra-channel-closed')
                            break
                        case LINK_STATUS.OPENED:
                            subTitle = this.$t('ready')
                            break
                        case LINK_STATUS.ERROR:
                            subTitle = `${this.$t('errors.error')}: ${error}`
                            break
                    }
                    break
                case CONNECT_STATUS.ERROR:
                    title = this.$t('turning-on-radio')
                    switch (this[GET_TETRA_CONNECT_ERROR]) {
                        case CONNECT_ERROR.DISCONNECTED:
                            subTitle = this.$t('no-tetra-conn')
                            break
                        case CONNECT_ERROR.BUSY:
                            subTitle = this.$t('busy-conn')
                            break
                        case CONNECT_ERROR.INVALID_SERVER_NAME:
                        case CONNECT_ERROR.INVALID_SSI:
                            subTitle = this.$t('incorrect-users-params')
                            break
                        default:
                            subTitle = this.$t('errors.unknown')
                    }
                    break
            }
            return {title, subTitle}
        },
        getActiveTexts() {
            let title = ''
            let subTitle = ''
            title = this[GET_TETRA_ACTIVE_GROUP] ? this[GET_TETRA_ACTIVE_GROUP].name : this.$t('group-not-selected')
            if (this[GET_TETRA_PTT_PUSHED]) subTitle = this.$t('word-query')
            return {title, subTitle}
        },
        getCallStatusTexts() {
            let title = ''
            let subTitle = ''
            let {callEvent, groupSSI, txSSIName, txSSI, releaseCause, txGrant} = (this[GET_TETRA_CALL] || {})
            if (groupSSI) {
                const group = (this[GET_TETRA_GROUPS] || {})[groupSSI]
                title = group ? group.name : `${this.$t('group')} ${groupSSI}`
            }
            switch (callEvent) {
                case CALL_EVENTS.STARTING:
                    subTitle = this.$t('word-query')
                    break
                case CALL_EVENTS.RELEASE:
                    subTitle = (this[GET_TETRA_PTT_PUSHED]) ? this.getReleaseCauseText(releaseCause) : this.$t('phone.call-ended')
                    break
                case CALL_EVENTS.TX_GRANT:
                    subTitle = this.getTxGrantText(txGrant, txSSIName, txSSI)
                    break
                case CALL_EVENTS.TX_INTERRUPT:
                    subTitle = this.$t('interfering')
                    break
                case CALL_EVENTS.CEASING:
                    subTitle = this.$t('silence')
                    break
            }
            return {title, subTitle}
        },
        getTxGrantText(txGrant, txSSIName, txSSI) {
            let text = ''
            switch (txGrant) {
                case TX_GRANT.GRANTED:
                    text = this.$t('speak')
                    break
                case TX_GRANT.NOT_GRANTED:
                    text = this.$t('not-grunted')
                    break
                case TX_GRANT.REQUEST_QUEUED:
                    text = this.$t('wait')
                    break
                case TX_GRANT.TO_ANOTHER_USER:
                    text = txSSIName ? `${txSSIName} (${txSSI})` : txSSI
                break
            }
            return text
        },
        getReleaseCauseText(releaseCause) {
            let text = ''
            switch (releaseCause) {
                case RELEASE_CAUSE.USER:
                    text = this.$t('phone.call-ended')
                    break
                case RELEASE_CAUSE.SERVICE_NOT_AVAILABLE:
                    text = this.$t('service-unavailable')
                    break
                case RELEASE_CAUSE.TIMEOUT:
                    text = this.$t('call-timeout')
                    break
                case RELEASE_CAUSE.SYSTEM:
                    text = this.$t('disc-by-system')
                    break
                default:
                    text = `${this.$t('phone.call-ended')} (${this.$t('code')} ${releaseCause})`
            }
            return text
        },
        onExit() {
            this[ACT_TETRA_EXIT_BTN]()
        },
        onInfo() {
            this.searchContacts(true)
        },
        onStartChat() {
            this.searchContacts(false, true)
        },
        searchContacts(isGroupContacts = false, isSDS = false) {
            this.modalOpen({
                name: 'search-tetra-contacts',
                component: SearchTetraContacts,
                props: {
                    isGroupContacts,
                    isSDS
                }
            })                    
        },
        chooseGroup() {
            this.modalOpen({
                name: 'tetra-radio-group-select'
            })
        },
        ...mapActions(TETRA, {
            [ACT_TETRA_CONNECT]: ACT_TETRA_CONNECT,
            [ACT_TETRA_DISCONNECT]: ACT_TETRA_DISCONNECT,
            [ACT_TETRA_SET_ECHO]: ACT_TETRA_SET_ECHO,
            pushPTT: ACT_TETRA_PUSH_PTT,
            popPTT: ACT_TETRA_POP_PTT,
            [ACT_TETRA_EXIT_BTN]: ACT_TETRA_EXIT_BTN,
            [ACT_TETRA_UPDATE_USERS_LIST]: ACT_TETRA_UPDATE_USERS_LIST,
        })
    }
}
