var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c("template", { slot: "default" }, [
        _vm.groupsList.length
          ? _c(
              "div",
              { staticClass: "group-list" },
              _vm._l(_vm.groupsList, function(group, index) {
                return _c(
                  "list-item",
                  {
                    key: index,
                    staticClass: "group",
                    attrs: {
                      title: group.titleText,
                      footer: group.footerText,
                      disabled: group.isNonSelectable
                    },
                    nativeOn: {
                      click: function($event) {
                        group.isNonSelectable
                          ? ""
                          : _vm.setGroupActive(group.gssi)
                      }
                    }
                  },
                  [
                    _vm.isGroupActive(group)
                      ? _c(
                          "div",
                          {
                            staticClass: "selected",
                            attrs: { slot: "after" },
                            slot: "after"
                          },
                          [_c("i", { staticClass: "far fa-check" })]
                        )
                      : _vm._e()
                  ]
                )
              }),
              1
            )
          : _c("span", [_vm._v(_vm._s(_vm.emptyText))])
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.modalClose } },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }