
import ModalContainer from "./ModalContainer.vue"

import ChatEdit from './ChatEdit.vue';
import ChannelEdit from './ChannelEdit.vue';
import PollEditor from './PollEditor.vue';
import ChannelUnavailable from './ChannelUnavailable.vue';
import RemoveChat from './RemoveChat.vue';
import LeaveChannel from '../channels/LeaveChannel.vue';
import DeleteChannel from '../channels/DeleteChannel.vue';
import SetBirthday from './SetBirthday.vue';
import RadioSelect from './RadioSelect.vue';
import TetraRadioGroupSelect from './TetraRadioGroupSelect.vue';
import SelectContact from './SelectContact.vue';
import SelectContactToPrivate from './SelectContactToPrivate.vue';
import CreateChat from './CreateChat.vue';
import SelectAssistant from './SelectAssistant.vue';
import AddToConf from './AddToConf.vue';
import videoConfModal from '../videoConfNew/videoConfModal.vue';
import AddConfMembers from './AddConfMembers.vue';
import ChannelMute from './ChannelMute.vue';
import ChatMute from './ChatMute.vue';
import RemoveChatMember from "./RemoveChatMember.vue";
import SelectContactsToChat from "./SelectContactsToChat.vue";
import SoundSettings from "./SoundSettings.vue";
import FontsSettings from "./FontsSettings.vue";
import SearchTetraContacts from "../radio/SearchTetraContacts.vue"
import TetraChat from "../radio/TetraChat.vue"

export default {
    name: "ModalContainerMain",
    extends: ModalContainer,
    components: {
        ChatEdit,
        ChannelEdit,
        PollEditor,
        ChannelUnavailable,
        RemoveChat,
        LeaveChannel,
        DeleteChannel,
        SetBirthday,
        TetraRadioGroupSelect,
        RadioSelect,
        SelectContact,
        SelectContactToPrivate,
        CreateChat,
        SelectAssistant,
        AddToConf,
        videoConfModal,
        AddConfMembers,
        ChannelMute,
        ChatMute,
        RemoveChatMember,
        SelectContactsToChat,
        SoundSettings,
        FontsSettings,
        SearchTetraContacts,
        TetraChat,
    },
}
