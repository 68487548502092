//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "RadioBtns",
    props: {
        groupSelect: {
            type: Boolean,
            default: false
        },
        isTetra: {
            type: Boolean,
            default: false
        },
        repeatLast: {
            type: Boolean,
            default: false
        },
        info: {
            type: Boolean,
            default: false
        },
        settings: {
            type: Boolean,
            default: false
        },
        missedMessagesCount: {
            type: [String, Number],
            default: '',
        }
    }
}
