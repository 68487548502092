//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconViewer from "../mediaViewer/IconViewer.vue"

export default {
    name: "InfoMainBlock",
    props: {
        iconPhoto: {
            type: String,
        },
        iconText: {
            type: String,
            required: true
        },
        commonText: {
            type: String,
            required: true
        },
        commonTitle: {
            type: String,
            required: false
        },
        additionalText: {
            type: String,
            required: false
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        favorites: {
            type: Boolean,
            required: false,
            default: false
        },
        favActive: {
            type: Boolean,
            required: false,
            default: false
        },
        official: {
            type: Boolean,
            required: false,
            default: false
        },
        iconViewer: {
            type: Boolean,
            required: false,
            default: false
        },
        iconType: {
            type: [String,Boolean],
            required: false,
            default: ''
        },
        iconId: {
            type: [String,Number,Boolean],
            required: false,
            default: ''
        },
        isAllowedToViewContact: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        imageLoaded: false,
        mainTitle: ''
    }),
    mounted() {
        this.mainTitle = this
    },
    computed: {
        iconClass() {
            return {
                clickable: this.iconViewer && this.imageLoaded && this.isAllowedToViewContact
            }
        },
    },
    methods: {
        onImageLoaded({loadedSrc}) {
            this.imageLoaded = loadedSrc
        },
        onEditBtnClick() {
            if (this.edit) this.$emit('edit')
        },
        onFavClick(fav) {
            this.$emit('fav', fav)
        },
        onIconClick() {
            if (!this.iconViewer || !this.imageLoaded) return
            this.$modal.show(IconViewer, {
                'src': this.imageLoaded,
                'type': 'image',
                'iconType': this.iconType,
                'id': this.iconId
            }, {
                name: 'viewer',
                delay: 200,
                width: '100%',
                height: '100%'
            });
        },
        contextMenuFio(e) {
            const handlers = []
            handlers.push({ item_name: this.$t('copy'), handler: () => {
                this.$copyText(this.commonText)
            } })
            this.cmOpen(e, handlers, 'right-bottom')
        }
    },
    watch: {
        commonTitle(val) {
        console.log("🚀 ~ file: InfoMainBlock.vue:134 ~ commonTitle ~ val:", val)

        },
        iconPhoto() {
            this.imageLoaded = false
        }
    },
}
