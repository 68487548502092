var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "reactions-picker-wrapper",
        class: { left: _vm.isLeftSide },
        style: _vm.pickerStyle,
        on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
      },
      [
        _c(
          "div",
          { staticClass: "reaction-popup" },
          [
            !_vm.isMouseOverToolbar
              ? _c(
                  "div",
                  {
                    staticClass: "reaction-icon",
                    class: { left: _vm.isLeftSide },
                    on: {
                      click: function($event) {
                        _vm.isShowToolbar = true
                      }
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "reaction-icon-emoji",
                      domProps: { innerHTML: _vm._s(_vm.getAppleEmoji("😀")) }
                    })
                  ]
                )
              : _vm._e(),
            _c("transition", { attrs: { name: "toolbox" } }, [
              _vm.isShowToolbar
                ? _c(
                    "div",
                    {
                      staticClass: "reaction-toolbar",
                      style: _vm.reactionToolbarStyle,
                      attrs: { id: "reactionToolbar" },
                      on: {
                        mouseover: _vm.mouseOverToolbar,
                        mouseleave: _vm.mouseLeaveToolbar
                      }
                    },
                    [
                      _vm._l(_vm.reactionEmos, function(reactionItem, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "reaction-item-wrapper" },
                          [
                            _c("div", {
                              staticClass: "reaction-item",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getAppleEmoji(reactionItem.reaction)
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onAddBaseReaction(
                                    reactionItem.reaction
                                  )
                                }
                              }
                            })
                          ]
                        )
                      }),
                      _c("div", { staticClass: "reaction-item-wrapper" }, [
                        _c("i", {
                          staticClass: "far fa-plus-circle reaction-item last",
                          on: { click: _vm.showEmojiPicker }
                        })
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }