//
//
//
//
//
//
//

export default {
    name: "CustomRoundBtn",
    props: {
        background: {
            type: Boolean,
            required: false,
            default: false
        },
        iconColor: {
            type: String,
            required: false,
            default: 'var(--icon-roundbtn-color)'
        },
        positionLeft: {
            type: Boolean,
            required: false,
            default: false
        },
        link: {
            type: Boolean,
            required: false,
            default: false
        },
        btnSize: {
            type: String,
            required: false,
            default: '40px'
        },
        subTitle: {
            type: String,
            required: false,
        },
        subTitleFontSize: {
            type: String,
            required: false,
            default: '11px'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false
        }
    },
    computed: {
        cssProps() {
            return {
                '--icon-color': this.disabled ? 'var(--icon-disabled-color)' : this.iconColor,
                '--icon-color-icon': this.disabled ? 'var(--icon-disabled-color)' : this.iconColor,
                '--btn-size': this.btnSize,
                ...(this.subTitle ? { '--sub-title-font-size': this.subTitleFontSize } : {})
            }
        },
        compTitle() {
            let title = this.title
            if (typeof title === 'string') {
                title = this.title.toLowerCase()
                title = title[0].toUpperCase() + title.slice(1)
            }
            return title
        },
        compSubtitle() {
            let title = this.title && this.subTitle 
            if (typeof title === 'string') title = title.toLowerCase()
            return title
        },
        classes() {
            const classes = {}
            if (this.positionLeft) classes['position-left'] = true
            if (this.background) classes['with-background'] = true
            if (this.link) classes['link'] = true
            if (this.disabled) classes['disabled'] = true
            return classes
        }
    },
    methods: {
        onClick() {
            if (!this.disabled) this.$emit('click')
        }
    }
}
