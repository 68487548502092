var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reactions-wrapper", style: _vm.reactionsWrapperStyle },
    [
      _c(
        "div",
        {
          staticClass: "reaction-items",
          on: { click: _vm.showReactionsPanel }
        },
        _vm._l(_vm.preparedAggrReactions, function(reactionItem, i) {
          return _c("div", { key: i, staticClass: "reaction-item-wrapper" }, [
            i < 3
              ? _c("div", { staticClass: "agg-reactions-show" }, [
                  _c("div", {
                    staticClass: "reaction-item",
                    domProps: {
                      innerHTML: _vm._s(_vm.wrapEmoji(reactionItem.reaction))
                    }
                  }),
                  _c("div", { staticClass: "reaction-item-count" }, [
                    _vm._v(_vm._s(reactionItem.count))
                  ])
                ])
              : _vm._e()
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }