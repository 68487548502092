import {
    ACT_BUILD_CONTACT_MESSAGE,
    ACT_BUILD_FILE_MESSAGE,
    ACT_BUILD_GEO_MESSAGE,
    ACT_BUILD_POOL_MESSAGE,
    ACT_BUILD_TEXT_MESSAGE,
    ACT_MODAL_OPEN,
    ACT_BUILD_IMAGE_MESSAGE,
    ACT_BUILD_VIDEO_MESSAGE,
    ACT_BUILD_AUDIO_MESSAGE,
    ACT_BUILD_REGULAR_FILE_MESSAGE,
    ACT_AJAX_SEND_FILE,
} from '../actionsTypes'

import { GET_MAX_UPLOAD_FILESIZE } from '../gettersTypes'

import {AJAX, MODAL, USERDATA} from '../modulesNames'
import {i18n} from '../../../ext/i18n'

import { generateHexString } from '../../common/Encrypter'
import * as declarations from '../../declarations.js'

export const FILE_TARGETS = {
    CHAT: 'chat',
    CHANNEL: 'channel',
}

const TARGET_FILE_VARIABLES = {
    [FILE_TARGETS.CHAT]: {
        url: declarations.http_post_dirs.CHAT_DATA_DIR,
        previewSize: 500,
        saveProportions: true
    },
    [FILE_TARGETS.CHANNEL]: {
        url: declarations.http_post_dirs.CHANNEL_DATA_DIR,
        previewSize: 900,
        saveProportions: false
    },
}

const locale = i18n.messages[i18n.locale]

const actions = {
    [ACT_BUILD_TEXT_MESSAGE]({dispatch}, ) {
        dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
            name: 'select-contacts-to-chat',
            props: payload
        }, {root: true})
    },
    [ACT_BUILD_CONTACT_MESSAGE]({dispatch, rootGetters}, payload = {}) {
        //@todo тут сильные отличия в чате и канале, сейчас нет смысла объединять
    },
    [ACT_BUILD_GEO_MESSAGE]({dispatch}, payload = {}) {
        return new Promise((resolve, reject) => {
            const { withText = false, latitude, longitude } = payload
            try {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'location',
                    props: {
                        latitude,
                        longitude,
                        withText,
                        btnOk: {
                            cb: ({latitude, longitude, text}) => resolve({
                                type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION,
                                latitude,
                                longitude,
                                text,
                            })
                        }
                    }
                }, { root: true })
            } catch (e) {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'alert',
                    props: {
                        text: locale['geo-not-available'],
                    },
                }, { root: true })
                reject(e)
            }
        })
    },
    [ACT_BUILD_POOL_MESSAGE]({dispatch}, payload = {}) {
        return new Promise((resolve) => {
            const { pool = false } = payload
            dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                name: 'poll-editor',
                props: {
                    pool,
                    cb: (newPool) => proto.addPoll((data) => {
                        data = {
                            type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL,
                            topic:  newPool.topic,
                            answers:  newPool.answers,
                            pollId: data.pollId
                        };
                        resolve(data)
                    })
                }
            }, { root: true })
        })
    },
    [ACT_BUILD_FILE_MESSAGE]({ rootGetters, dispatch }, {file, fileGroupId, text, enc, target = FILE_TARGETS.CHAT}) {
        return new Promise(async (resolve, reject) => {
            let result
            let extension = getFileExtension(file)
            if (!extension) extension = file.type.split('/')[1] || ''
            const maxUploadFileSizeSet = rootGetters[`${USERDATA}/${GET_MAX_UPLOAD_FILESIZE}`]
            const maxUploadFileSize = maxUploadFileSizeSet * 1024 * 1024 || declarations.msgLimits.maxFileSize
            if (file.size > maxUploadFileSize) {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'alert',
                    props: {
                        //title: this.$t('errors.error'),
                        text: locale['max-file-size'] + maxUploadFileSize / (1024 * 1024) + locale.mb,
                    },
                }, { root: true })
                return reject(new Error('file size limit'))
            }
            const mime = await readFileMimeType(file)
            let type = mime.split('/')[0] || 'unknown'

            if (enc) {
                if (!isFileEncryptionPossible(mime)) reject(new Error('Wrong mime type for encryption'))
                else result = await dispatch(ACT_BUILD_REGULAR_FILE_MESSAGE, {file, extension, enc, target})
            } else {
                switch (type) {
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
                        result = await dispatch(ACT_BUILD_IMAGE_MESSAGE, {file, fileGroupId, extension, target})
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
                        result = await dispatch(ACT_BUILD_AUDIO_MESSAGE, {file, extension, target})
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
                        result = await dispatch(ACT_BUILD_VIDEO_MESSAGE, {file, fileGroupId, extension, target})
                        break
                    default:
                        result = await dispatch(ACT_BUILD_REGULAR_FILE_MESSAGE, {file, extension, target})
                }
            }
            resolve(result)
        })
    },
    async [ACT_BUILD_IMAGE_MESSAGE]({dispatch}, {file, fileGroupId, extension, target = FILE_TARGETS.CHAT }){
        let imageProps
        let previewFileName
        let fileName
        let vars = TARGET_FILE_VARIABLES[target]
        let url = vars.url
        try {
            imageProps = await getImageProps({file, scaleSize: vars.previewSize, saveProportions: vars.saveProportions})
        } catch (e) {
            return dispatch(ACT_BUILD_REGULAR_FILE_MESSAGE, { file, extension })
        }

        try {
            previewFileName = await dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                url,
                file: imageProps.preview_blob,
            }, { root: true })
        } catch (e) {}
        if (!previewFileName) return
        try {
            fileName = await dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                url,
                file,
            }, { root: true })
        } catch (e) {}
        if (!fileName) return
        return {
            type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE,
            size: file.size,
            extension: extension,
            file: fileName,
            fileGroupId,
            previewFile: previewFileName,
            previewSize: { w: imageProps.preview_size.w, h: imageProps.preview_size.h },
        }
    },
    async [ACT_BUILD_VIDEO_MESSAGE]({dispatch}, { file, fileGroupId, extension, target = FILE_TARGETS.CHAT }){
        try {
            let previewFileName
            let fileName
            let vars = TARGET_FILE_VARIABLES[target]
            let url = vars.url
            const videoProps = await getVideoProps(file, vars.previewSize, vars.saveProportions)
            try {
                previewFileName = await app.store.dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                    url,
                    file: videoProps.preview_blob,
                }, { root: true })
            } catch (e) {}
            if (!previewFileName) return
            try {
                fileName = await app.store.dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                    url,
                    file,
                }, { root: true })
            } catch (e) {}
            if (!fileName) return

            return {
                type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO,
                size: file.size,
                extension: extension,
                file: fileName,
                fileGroupId,
                previewFile: previewFileName,
                duration: videoProps.duration,
                previewSize: { w: videoProps.preview_size.w, h: videoProps.preview_size.h },
            }
        } catch (e) {
            return new Promise((resolve, reject) => {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'confirm',
                    props: {
                        text: locale['video-format-not-support'],
                        btnOk: {
                            cb: () => resolve(dispatch(ACT_BUILD_REGULAR_FILE_MESSAGE, {file, extension}, {root: true}))
                        },
                        btnCancel: {
                            cb: () => reject(e)
                        }
                    }
                }, { root: true })
            })
        }
    },
    async [ACT_BUILD_AUDIO_MESSAGE]({dispatch}, { file, extension, target = FILE_TARGETS.CHAT }){
        const result = {
            type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO,
            size: file.size,
            extension: extension,
        }
        let vars = TARGET_FILE_VARIABLES[target]
        let url = vars.url
        if (file instanceof File && file.name) result.name = file.name.split(".").shift()

        try {
            let audio_props = await getAudioProps(file)
            result.duration = audio_props.duration
        } catch (e) {
            return new Promise((resolve, reject) => {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'confirm',
                    props: {
                        text: locale['audio-format-not-support'],
                        btnOk: {
                            cb: () => resolve(dispatch(ACT_BUILD_REGULAR_FILE_MESSAGE, {file, extension}, {root: true}))
                        },
                        btnCancel: {
                            cb: () => reject(e)
                        }
                    }
                }, { root: true })
            })
        }
        result.file = await dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
            url,
            file,
        }, { root: true })
        return result
    },
    async [ACT_BUILD_REGULAR_FILE_MESSAGE]({dispatch}, { file, extension, enc = false, target = FILE_TARGETS.CHAT }){
        let vars = TARGET_FILE_VARIABLES[target]
        let url = vars.url
        const splitedName = file.name.split('.')
        extension = splitedName.length > 1 ? splitedName.pop() : ''

        const result = {
            type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE,
            size: file.size,
            extension,
            name: splitedName.join('.'),
        }

        if (enc) {
            result.readOnly = true
            result.key = await generateHexString()
        }

        result.file = await dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
            url,
            file,
            encKey: result.key,
        }, { root: true })
        return result
    },

    async actGetVideoImgPreview({}, fileDataSrc){
        return new Promise((resolve, reject) => {
            const video = document.createElement('video')
            video.oncanplay = () => {
                try {
                    let previewData = getImagePreview(video, video.videoWidth, video.videoHeight, 500, 0, true)
                    resolve({
                        duration: video.duration,
                        preview: previewData.base64,
                        preview_blob: previewData.blob,
                        preview_size: {
                            w: previewData.width,
                            h: previewData.height
                        }
                    })
                    window.URL.revokeObjectURL(video.src)
                } catch (e) {
                    reject(e)
                }
            }
    
            video.onerror = (e) => {
                reject(e)
            }
            video.onabort = (e) => {
                reject(e)
            }
            fileDataSrc = fileDataSrc.replace('video/quicktime', 'video/mp4')
            video.src = fileDataSrc
            video.currentTime = 1
        })
    },
}

async function readFileMimeType(file) {
    return new Promise((resolve, reject) => {
        try {
            let fileReader = new FileReader()
            let extension = getFileExtension(file)
            fileReader.onloadend = () => resolve(getMimeType({arrayBuffer: fileReader.result, extension}))
            fileReader.onerror = reject
            fileReader.readAsArrayBuffer(file)
        } catch (e) {
            reject(e)
        }
    })
}

function getFileExtension(file) {
    if (!file || !file.name) return ''
    const splited_name = file.name.split('.')
    return (splited_name.length > 1 ? splited_name.pop() : '').toLowerCase()
}

function getMimeType({arrayBuffer, extension}) {
    let mime = ''
    let fileSignature = ''
    let fullHeader = ''
    if (arrayBuffer) {
        let arr = (new Uint8Array(arrayBuffer)).subarray(0, 16)
        let header = ''
        for (let i = 0; i < arr.length; i++) {
            fullHeader += arr[i].toString(16).toUpperCase().padStart(2, 0)
        }
        header = fullHeader.substr(0, 8)
        fileSignature = declarations.fileSignatures[header] || declarations.fileSignatures[header.substr(0, 6)]
    }
    switch (fileSignature) {
        case 'JPEG':
        case 'JPEG1':
        case 'JPEG2':
        case 'JPEG3':
        case 'JPEG8':
            mime = 'image/jpeg'
            break;
        case 'PNG':
            mime = 'image/png'
            break;
        case 'GIF':
            mime = 'image/gif'
            break;
        case 'MP4':
            mime = 'video/mp4'
            break;
        case 'M4V':
            mime = 'video/mp4'
            break;
        case 'MOV':
            mime = 'video/mp4'
            break;
        case '3GP':
            mime = 'video/3gp'
            break;
        case 'MP3':
            mime = 'audio/mpeg'
            break;
        case 'RIFF':
            let file = fullHeader.substr(16, 8)
            switch (declarations.riffFileSignatures[file]) {
                case 'WAV':
                    mime = 'audio/vnd.wave'
                    break
                case 'WEBP':
                    mime = 'image/webp'
            }
            break;
        case 'PDF':
            mime = 'application/pdf'
            break
        default:
            switch (extension) {
                case 'mp3':
                    mime = 'audio/mpeg'
                    break;
                case 'wav':
                    mime = 'audio/vnd.wave'
                    break;
                case 'mp4':
                    mime = 'video/mp4'
                    break;
                case 'm4v':
                    mime = 'video/mp4'
                    break;
                case 'mov':
                    mime = 'video/mp4'
                    break;
                case 'mkv':
                    mime = 'video/mp4'
                    break;
                case '3gp':
                    mime = 'video/3gp'
                    break;
                case 'webp':
                    mime = 'image/webp'
                    break
                case 'pdf':
                    mime = 'application/pdf'
                    break
            }
    }
    return mime
}

function isFileEncryptionPossible(mime) {
    let possibleMimes = [
        /video\/*/,
        /image\/*/,
        /application\/pdf/,
    ]
    return !!possibleMimes.find((reg) => reg.test(mime))
}

async function getVideoProps(file, scale_size, saveProportions)  {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.oncanplay = () => {
            try {
                let previewData = getImagePreview(video, video.videoWidth, video.videoHeight, scale_size, 0, saveProportions)
                resolve({
                    duration: video.duration,
                    preview: previewData.base64,
                    preview_blob: previewData.blob,
                    preview_size: {
                        w: previewData.width,
                        h: previewData.height
                    }
                })
                window.URL.revokeObjectURL(video.src)
            } catch (e) {
                // console.log("!! -> file: FileWorker.js -> line 106 -> self.getVideoProps= -> e", e)
                reject(e)
            }
        }

        video.onerror = (e) => {
            //e.target.error.* error codes
            //e.target.error.code current error code
            reject(e)
        };

        video.onabort = (e) => {
            reject(e)
        }

        video.src = URL.createObjectURL(file)
        video.currentTime = 1
    })
}


async function getImageProps({file, scaleSize, saveProportions}) {
    return new Promise((resolve, reject) => {
        //Создаем холст для отрисовки
        //Читаем файл
        const reader = new FileReader()

        reader.onload = () => {
            //Грузим в картинку
            const image = new Image()
            image.onload = async () => {
                let orientation = await utils.getOrientation(file)
                try {
                    let previewData = getImagePreview(image, image.width, image.height, scaleSize, orientation, saveProportions)
                    let props = {
                        preview: previewData.base64,
                        preview_blob: previewData.blob,
                        preview_size: {
                            w: previewData.width,
                            h: previewData.height
                        }
                    }
                    resolve(props)
                } catch (e) {
                    return reject(e)
                }
            }
            image.onerror = () => {
                reject(new Error('File render error'))
            }
            image.onabort = () => {
                reject(new Error('File render abort'))
            }
            image.title = file.name
            image.src = reader.result
        }
        reader.onerror = () => {
            reject(new Error('File read error'))
        }
        reader.onabort = () => {
            reject(new Error('File read abort'))
        }
        reader.readAsDataURL(file)
    })

}

/**
 *
 * @param {Element} source видео/аудио источник
 * @param {Number} source_width ширина источника
 * @param {Number} source_height высота источника
 * @param {Number} scale_size требуемая высота/ширина
 * @param {Boolean} saveProportions сохранить пропорции
 * @param orientation
 */
function getImagePreview(source, source_width, source_height, scale_size, orientation, saveProportions) {
    let result = {},
        canvas = getPreviewCanvas(source, source_width, source_height, scale_size, orientation/*, saveProportions*/);
    result['base64'] = canvas.toDataURL().split(",").pop() //testFullBase64.split(",").pop();
    result['blob'] = b64toBlob(result['base64'], 'image/png');
    result['width'] = saveProportions  ? source_width : canvas.width;
    result['height'] = saveProportions  ? source_height : canvas.height;
    return result
}

function getPreviewCanvas(source, source_width, source_height, scale_size, orientation, saveProportions) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    let iw
    let ih
    let shift_w
    let shift_h
    let capturedW
    let capturedH
    // if (saveProportions) {
        const proportions = source_width / source_height
        if (Math.max(source_width, source_height) > scale_size) {
            if (proportions === 1) { // ширина и длина равны
                iw = scale_size
                ih = scale_size
            } else if (proportions > 1) { // ширина больше длины
                iw = scale_size
                ih = scale_size / proportions
            } else { // ширина меньше длины
                iw = scale_size * proportions
                ih = scale_size
            }
        } else {
            iw = source_width
            ih = source_height
        }
        shift_w = 0
        shift_h = 0
        capturedW = source_width
        capturedH = source_height
        canvas.width = iw
        canvas.height = ih
    // } else {
    //     iw = source_width
    //     ih = source_height
    //     shift_w = iw < ih ? 0 : (iw - ih) / 2
    //     shift_h = ih < iw ? 0 : (ih - iw) / 2
    //     capturedW = (iw - 2 * shift_w)
    //     capturedH = (ih - 2 * shift_h)
    //     canvas.width = scale_size
    //     canvas.height = scale_size
    // }

    if(orientation !== 1) {
        switch(orientation) {
            case 3:
                ctx.rotate(180*Math.PI/180);
                ctx.translate(iw, -ih);
                break;
            case 6:
                ctx.rotate(90*Math.PI/180);
                ctx.translate(0, -ih);
                break;
            case 8:
                ctx.rotate(-90*Math.PI/180);
                ctx.translate(iw, 0);
                break;
        }
    }

    //
    ctx.drawImage(source, shift_w, shift_h, capturedW, capturedH, 0, 0, iw, ih)

    return canvas;
}

function b64toBlob(b64Data, contentType, sliceSize = 512) {
    contentType = contentType || '';

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

function getAudioProps (file) {
    return new Promise((resolve, reject) => {
        const audio = document.createElement('audio')

        //video.preload = 'metadata';

        audio.oncanplay = () => {
            resolve({duration: audio.duration || 0})
            window.URL.revokeObjectURL(audio.src)
        }

        audio.onerror = reject

        audio.onabort = reject

        audio.src = URL.createObjectURL(file)
    })
}

export default {
    namespaced: true,
    actions,
}