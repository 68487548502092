'use strict'

import {
    GET_USER_PARAMS,
    GET_RADIO_PARAMS,
    GET_CURRENT_RADIO,
    GET_CURRENT_RADIO_TYPE,
    GET_SPEECH_PACK_RATE,
    GET_SPEECH_BUFFER_EMPTY,
    GET_DUPLEX_SPEECH_BUFFER_EMPTY,
    GET_PTT_POP_AWAITING,
    RADIO_IS_TURN_ON,
    GET_CURRENT_RADIO_CON_PARAMS,
    TETRA_IS_TURN_ON,
    RC_RADIO_IS_TURN_ON,
    GET_RC_RADIO_ACTIVE_GROUP,
    GET_RADIO_SETTINGS,
    GET_RADIO_TOKEN,
    GET_TETRA_TOKEN,
    GET_RC_RADIO_TOKEN,
    GET_ACTIVE_MICROPHONE,
} from '../gettersTypes'

import {
    ACT_SET_CURRENT_RADIO,
    ACT_TETRA_DISCONNECT,
    ACT_SET_SPEECH_PACK_RATE,
    ACT_SET_SPEECH_BUFFER_EMPTY,
    ACT_SET_DUPLEX_SPEECH_BUFFER_EMPTY,
    ACT_SET_PTT_POP_AWAITING,
    ACT_RADIO_STATE_TOGGLE,
    ACT_TETRA_CONNECT_STATE_TOGGLE,
    ACT_RC_RADIO_CONNECT_STATE_TOGGLE,
    ACT_RC_RADIO_DISCONNECT,
    ACT_SET_RADIO_SETTINGS,
    ACT_REQUEST_TOKEN,
    ACT_RC_RADIO_REQUEST_TOKEN,
    ACT_TETRA_REQUEST_TOKEN,
} from '../actionsTypes'

import {
    MUT_SET_CURRENT_RADIO_INDEX,
    MUT_SET_RADIO_SETTINGS,
} from '../mutationsTypes'

import {
    USERDATA,
    TETRA,
    RC_RADIO,
} from '../modulesNames'

export const RADIO_TYPE = {
    ROSCHAT: 'roschat',
    TETRA: 'tetra-radio'
}

// Режимы работы PTT
export const PTT_BTN_MODES = {
    DEFAULT:    'default',  // нажал-говоришь/отжал-закончил
    SWITCH:     'switch',   // нажал-говоришь/нажал-закончил
    NONE:       'disabled', // нажатие на кнопку заблокировано
}

const state = {
    currentRadioIndex: 0,
    speechPackRate: 0,
    bufferEmpty: true,
    duplexBufferEmpty: true,
    pttPopAwaiting: false,
    settings: {
        pttBtnMode: PTT_BTN_MODES.DEFAULT,
        signalsSound: true,
    }
}

const getters = {
    [GET_RADIO_PARAMS]: (state, getters, rootState, rootGetters) => {
        const params = rootGetters[`${USERDATA}/${GET_USER_PARAMS}`]
        let result = []
        if (params && params.services) {
            params.services.forEach(item => {
                if (item.type === 'radio') {
                    let obj = {...item}
                    obj.type = RADIO_TYPE.ROSCHAT
                    result.push(obj)
                }
                if ((item.type === 'tetra' && item.servers))
                    item.servers.forEach((item) => {
                        let obj = {...item}
                        obj.type = RADIO_TYPE.TETRA
                        result.push(obj)
                    })
            })
        } else if (params && params.phones) {
            if (params.phones.radio) result.push({...{type: RADIO_TYPE.ROSCHAT}, ...params.phones.radio})
            if (params.phones.tetra) params.phones.tetra.forEach((params) => result.push({...{type: RADIO_TYPE.TETRA}, ...params}))
        }
        return result
    },
    [GET_CURRENT_RADIO]: (state, getters) => {
        let index = state.currentRadioIndex
        if (index > getters[GET_RADIO_PARAMS].length - 1) index = 0
        return getters[GET_RADIO_PARAMS][index] || null
    },
    [GET_CURRENT_RADIO_TYPE]: (state, getters) => {
        return getters[GET_CURRENT_RADIO] && getters[GET_CURRENT_RADIO].type
    },
    [GET_CURRENT_RADIO_CON_PARAMS]: (state, getters) => {
        let params = {}
        const radio = getters[GET_CURRENT_RADIO]
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.TETRA) {
            params = {ssi: radio.ssi, name: radio.name}
        }
        return params
    },
    [GET_SPEECH_PACK_RATE]: (state) => {
        return state.speechPackRate
    },
    [GET_SPEECH_BUFFER_EMPTY]: (state) => {
        return state.bufferEmpty
    },
    [GET_DUPLEX_SPEECH_BUFFER_EMPTY]: (state) => {
        return state.duplexBufferEmpty
    },
    [GET_PTT_POP_AWAITING]: (state) => {
        return state.pttPopAwaiting
    },
    [RADIO_IS_TURN_ON]: (state, getters, rootState, rootGetters) => {
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.TETRA) return rootGetters[`${TETRA}/${TETRA_IS_TURN_ON}`]
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.ROSCHAT) return rootGetters[`${RC_RADIO}/${RC_RADIO_IS_TURN_ON}`]
        else return false
    },
    [GET_RADIO_SETTINGS]: (state, getters, rootState, rootGetters) => {
        const microphone = rootGetters[`${USERDATA}/${GET_ACTIVE_MICROPHONE}`]
        const micIsActive = Boolean(microphone && microphone.deviceId)
        return (micIsActive) ? state.settings : { ...state.settings, ...{ pttBtnMode: PTT_BTN_MODES.NONE }}
    },
    [GET_RADIO_TOKEN]: (state, getters, rootState, rootGetters) => {
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.TETRA) return rootGetters[`${TETRA}/${GET_TETRA_TOKEN}`]
        return rootGetters[`${RC_RADIO}/${GET_RC_RADIO_TOKEN}`]
    },
}

const actions = {
    [ACT_SET_CURRENT_RADIO]: ({state, getters, dispatch, commit}, payload) => {
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.TETRA) dispatch(`${TETRA}/${ACT_TETRA_DISCONNECT}`, null, {root: true})
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.ROSCHAT) dispatch(`${RC_RADIO}/${ACT_RC_RADIO_DISCONNECT}`, null, {root: true})
        commit(MUT_SET_CURRENT_RADIO_INDEX, getters[GET_RADIO_PARAMS].findIndex(item => item === payload))
    },
    [ACT_SET_SPEECH_PACK_RATE]: ({state}, rate) => {
        if (state.speechPackRate !== rate) state.speechPackRate = rate
    },
    [ACT_SET_SPEECH_BUFFER_EMPTY]: ({state}, empty) => {
        if (state.bufferEmpty !== empty) state.bufferEmpty = empty
    },
    [ACT_SET_DUPLEX_SPEECH_BUFFER_EMPTY]: ({state}, empty) => {
        if (state.duplexBufferEmpty !== empty) state.duplexBufferEmpty = empty
    },
    [ACT_SET_PTT_POP_AWAITING]: ({state}, idle) => {
        if (state.pttPopAwaiting !== idle) state.pttPopAwaiting = idle
    },
    [ACT_RADIO_STATE_TOGGLE]: ({getters, dispatch, rootGetters}, payload = {}) => {
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.TETRA) {
            dispatch(`${TETRA}/${ACT_TETRA_CONNECT_STATE_TOGGLE}`, {...payload, ...getters[GET_CURRENT_RADIO_CON_PARAMS]}, {root: true})
        }
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.ROSCHAT) {
            let params = {...payload, ...getters[GET_CURRENT_RADIO_CON_PARAMS]}
            let channelId = 0
            const { autoTurnOn } = payload // autoTurnOn = true - auto open support assistant radio
            if (!autoTurnOn) channelId = rootGetters[`${RC_RADIO}/${GET_RC_RADIO_ACTIVE_GROUP}`]
            if (channelId || autoTurnOn) params = {...params, ...{channelId}}
            dispatch(`${RC_RADIO}/${ACT_RC_RADIO_CONNECT_STATE_TOGGLE}`, params, {root: true})
        }
    },
    [ACT_SET_RADIO_SETTINGS]: ({commit}, payload) => {
        commit(MUT_SET_RADIO_SETTINGS, payload)
    },
    [ACT_REQUEST_TOKEN]: ({state, getters, dispatch, commit}, {channelId}) => {
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.ROSCHAT) dispatch(`${RC_RADIO}/${ACT_RC_RADIO_REQUEST_TOKEN}`, {channelId}, {root: true})
        if (getters[GET_CURRENT_RADIO_TYPE] === RADIO_TYPE.TETRA) dispatch(`${TETRA}/${ACT_TETRA_REQUEST_TOKEN}`, {channelId}, {root: true})
    },
}

const mutations = {
    [MUT_SET_CURRENT_RADIO_INDEX]: (state, index) => {
        state.currentRadioIndex = index
    },
    [MUT_SET_RADIO_SETTINGS]: (state, {prop, value}) => {
        Object.assign(state.settings, {[prop]: value})
        //state.settings = settings
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
