//
//
//
//
//
//
//
//
//

export default {
    props:['type', 'disable'],
    name: 'phone-action-button',
    mounted() {
        console.log('AB', this.type)
    },
    methods: {
        click() {
            this.$emit('click')
        }
    }
}
