//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainContentContainer from "../main/body/MainContentContainer.vue"
import moment from 'moment'
import ContentLoader from "../main/body/MainContentLoader.vue"
import MeetingScheduler from "../modal/integrations/MeetingScheduler.vue"

import { INTEGRATIONS } from '../../store/modulesNames'
import { 
    ACT_SCHEDULE_SYNC_APPOINTMENTS,
    ACT_SCHEDULE_ADD_APPOINTMENT,
    ACT_SCHEDULE_EDIT_APPOINTMENT,
    ACT_SCHEDULE_DELETE_APPOINTMENT,
    ACT_SCHEDULE_GET_APPOINTMENT,
    ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS,
} from '../../store/actionsTypes'
import { GET_IS_MSEXCHANGE_AUTH } from '../../store/gettersTypes'

import { mapActions, mapGetters } from '../../../vuex'

export default {
        name: "integrations",
        components: { MainContentContainer, ContentLoader, MeetingScheduler },
        data() {
            return {
                currentYear: moment().year(),
                currentMonth: 0,
                days: [],
                selectedDay: {},
                clickedDay: {},
                loader: false,
                refresh: false
            }
        },
        async mounted() {
            this.loader = true
            await this.syncAppointments()
            this.loader = false
            this.currentMonth = moment().month()
        },
        computed: {
            getDays() {
                return this.days
            },
            getToday() {
                return this.days.find(d => d.isToday)
            },
            isAuthSuccessful() {
                return this[GET_IS_MSEXCHANGE_AUTH]
            },
            ...mapGetters(INTEGRATIONS, [GET_IS_MSEXCHANGE_AUTH]),
        },
        watch: {
            async currentMonth() {
                this.refresh = true
                this.days = []
                this.days = await this.getCalendarDays()
                this.refresh = false
            }
        },
        methods: {
            ...mapActions(INTEGRATIONS, [    
                ACT_SCHEDULE_SYNC_APPOINTMENTS,
                ACT_SCHEDULE_ADD_APPOINTMENT,
                ACT_SCHEDULE_EDIT_APPOINTMENT,
                ACT_SCHEDULE_DELETE_APPOINTMENT,
                ACT_SCHEDULE_GET_APPOINTMENT,
                ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS,
            ]),
            async syncAppointments() {
                this.refresh = true
                let reply = await this[ACT_SCHEDULE_SYNC_APPOINTMENTS]({ type: "exchange" }) || {}
                if (!reply.hasOwnProperty('error')) this.days = await this.getCalendarDays()
                this.refresh = false
            },
            async getCalendarAppointments(startDate, endDate) {
                return await this[ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS]({ type: "exchange", startDate, endDate })
            },
            async getCalendarAppointment(id) {
                return await this[ACT_SCHEDULE_GET_APPOINTMENT]({ type: "exchange", id })
            },            
            monthUp() {
                const startDate = moment([this.currentYear, this.currentMonth])
                const changedDate = moment(startDate).add(1, 'month')
                this.currentYear = changedDate.year()
                this.currentMonth = changedDate.month()
            },
            monthDown() {
                const startDate = moment([this.currentYear, this.currentMonth])
                const changedDate = moment(startDate).add(-1, 'month')
                this.currentYear = changedDate.year()
                this.currentMonth = changedDate.month()
            },
            async getCalendarDays() {
                let m = moment()
                let currentYear = this.currentYear
                let currentMonth = this.currentMonth
                let prevMonth = currentMonth - 1
                let nextMonth = currentMonth + 1
                let currentMonthName = m.month(currentMonth).format('MMM').slice(0,3)
                let currentMonthYear = m.month(currentMonth).format('YYYY')
                let prevMonthName = moment().month(prevMonth).format('MMM').slice(0,3)
                let prevMonthYear = moment().month(prevMonth).format('YYYY')
                let nextMonthName = moment().month(nextMonth).format('MMM').slice(0,3)
                let nextMonthYear = moment().month(nextMonth).format('YYYY')

                const getDaysByMonthYear = (month, year) => {
                    let daysInMonth = m.month(month).daysInMonth()
                    let monthStr = (month + 1).toString().length > 1 ? (month + 1).toString() : '0' + (month + 1).toString()
                    let date = `${year}-${monthStr}`
                    return Array.from({length: daysInMonth}, (k, l) => {
                        let dayStr = (l+1).toString().length > 1 ? (l+1).toString() : '0' + (l+1).toString()
                        return { dayNumber: l + 1, date: date + '-' + dayStr }
                    })
                }

                const currentMDays = getDaysByMonthYear(currentMonth, currentMonthYear) 
                const prevMDays = getDaysByMonthYear(prevMonth, prevMonthYear) 
                const nextMDays = getDaysByMonthYear(nextMonth, nextMonthYear) 

                let startDate = moment([currentYear, currentMonth])
                let startOfMonthDayNum = moment(startDate).startOf('month').day()
                let startDateStr = moment(startDate).startOf('month').format('YYYY-MM-DD')
                let prevMDaysGap = startOfMonthDayNum ? startOfMonthDayNum - 1 : 6
                if (prevMDaysGap) startDateStr = moment(startDate).add(0-prevMDaysGap, 'day').format('YYYY-MM-DD')
                let endOfMonthDayNum = moment(startDate).endOf('month').day()
                let endDateStr = moment(startDate).endOf('month').format('YYYY-MM-DD')
                let nextMDaysGap = 7 - endOfMonthDayNum
                let slicedPrevMDays = prevMDaysGap ? prevMDays.slice(-prevMDaysGap) : []
                let slicedNextMDays = nextMDays.slice(0, nextMDaysGap)  // nextMDaysGap % 7 ? [] : 
                let daysArray = [...slicedPrevMDays, ...currentMDays]
                if (nextMDaysGap !== 7) {
                    daysArray = [...daysArray, ...slicedNextMDays]
                    endDateStr = moment(startDate).endOf('month').add(nextMDaysGap, 'day').format('YYYY-MM-DD')
                }
                let serverEvents = []
                const isAuthSuccessful = this.isAuthSuccessful
                if (isAuthSuccessful) serverEvents = await this.getCalendarAppointments(startDateStr, endDateStr)
                let grouppedEvents = _groupArrayByField(serverEvents, 'date')

                let daysObjArr = []
                daysArray.map((d, i) => {
                    let dayObj = { dayNumber: d.dayNumber, date: d.date, events: [] }
                    const isToday = moment().date() + prevMDaysGap - 1 === i && this.currentMonth === moment().month()
                    if (isToday) dayObj.isToday = isToday
                    if (grouppedEvents[d.date]) {
                        let grEvents = grouppedEvents[d.date].sort((a, b) => { return (a.startTime < b.startTime) ? -1 : 1 })
                        grEvents.forEach(ge => {
                            if (!ge.subject) ge.subject = this.$t('chat.schedule-names.no-name')
                        })
                        dayObj.events = grEvents
                    }
                    if (i === 0 && prevMDaysGap) dayObj.monthName = prevMonthName
                    if (d.dayNumber === 1) dayObj.monthName = currentMonthName
                    if (nextMDaysGap && nextMDaysGap !== 7 && i === daysArray.length - nextMDaysGap) dayObj.monthName = nextMonthName
                    if (prevMDaysGap && i >=0 && i < prevMDaysGap) dayObj.notCurrentMonth = true
                    const beforeNextDays = prevMDaysGap + currentMDays.length
                    if (nextMDaysGap && i >= beforeNextDays && i <= daysArray.length) dayObj.notCurrentMonth = true
                    daysObjArr.push(dayObj)
                })
                return daysObjArr
            },
            getWeekDays() {
                return moment.weekdays(true)
            },
            getCurrentMonthYear() {
                let startDate = moment([this.currentYear, this.currentMonth])
                return moment(startDate).format('MMMM YYYY')
            },
            async createEvent() {
                let day = this.selectedDay
                if (!day) day = this.getToday
                this.modalOpen({
                    component: MeetingScheduler,
                    props: { day, type: 'create',
                        cb: ({createdEvent, serverReply}) => {
                            const error = serverReply.error
                            if (serverReply && error) {
                                this.showErrorAlert(error)
                                return                           
                            }
                            const startDT = createdEvent.startTime
                            const endDT = createdEvent.endTime
                            let m_dt = moment.unix(startDT)
                            const dateStr = m_dt.format("YYYY-MM-DD")
                            const startTimeStr = m_dt.format("HH:mm:ss")
                            m_dt = moment.unix(endDT)
                            const endTimeStr = m_dt.format("HH:mm:ss")
                            createdEvent.date = dateStr
                            createdEvent.startTime = startTimeStr
                            createdEvent.endTime = endTimeStr
                            let calendar_day = this.days.find(d => d.date === dateStr)
                            if (calendar_day && calendar_day.events && Array.isArray(calendar_day.events)) {
                                calendar_day.events.push(createdEvent)
                            }
                            setTimeout(() => { this.syncAppointments() }, 300)
                        }
                    }
                })
            },
            async editEvent(day, event) {
                const id = event.id
                let appointmentEvent = await this.getCalendarAppointment(id)
                this.modalOpen({
                    component: MeetingScheduler,
                    props: { 
                        day, event: appointmentEvent, type: 'edit',
                        cb: ({actionType, changedEvent, serverReply}) => {
                            const error = serverReply.error
                            if (serverReply && error) {
                                this.showErrorAlert(error)
                                return                           
                            }
                            if (actionType === 'delete') {
                                for(let i = 0; i < day.events.length; i++) {
                                    if (day.events[i].id === id) day.events.splice(i, 1)
                                }
                            } else Object.assign(event, changedEvent)
                            setTimeout(() => { this.syncAppointments() }, 300) 
                        }
                    }
                })
            },
            showErrorAlert(error) {
                let text = ''
                if (error = 'uknown') text = this.$t('errors.unknown')
                else text = error
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text,
                    },
                    modalProps: {
                        pivotY: 0.45,
                    }
                })
            },
            outerClick(e) {
                if (e.target.className !== "day") {
                    this.$set(this.clickedDay, 'clicked', false)
                    this.clickedDay = {}
                }
            },
            dayClicked(day) {
                if (!this.isAuthSuccessful) return
                this.selectedDay = day
                this.$set(this.clickedDay, 'clicked', false)
                if (this.clickedDay && this.clickedDay.dayNumber !== day.dayNumber ) this.clickedDay = {}
            },
            dayDblClicked(day) {
                if (!this.isAuthSuccessful) return
                this.$set(this.clickedDay, 'clicked', false)
                this.clickedDay = day
                this.$set(this.clickedDay, 'clicked', true)
            },
            getClickedDate(day) {
                if (!(day && day.clicked)) return
                return moment(day.date).format('dddd, DD MMMM, YYYY')
            },
            getEventTitle(event) {
                let date = moment(event.date).format('DD MMMM, YYYY')
                let { sTime, eTime } = this.getTimePeriod(event)
                let title = date + '\n' + sTime + '-' + eTime + ' ' + event.subject
                return title
            },
            getTimePeriod(event) {
                let sTime = this.getStartTimeFormatted(event), eTime = this.getEndTimeFormatted(event)
                return { sTime, eTime }
            },
            getStartTimeFormatted(event) {
                if (typeof event.startTime === 'number') return moment.unix(event.startTime).format('HH:mm')
                return moment(event.date + ' ' + event.startTime).format('HH:mm')
            },
            getEndTimeFormatted(event) {
                if (typeof event.endTime === 'number') return moment.unix(event.endTime).format('HH:mm')
                return moment(event.date + ' ' + event.endTime).format('HH:mm')
            },
            beforeDestroy() {
                const mainContent = document.getElementById('main-content')
                if (mainContent) mainContent.removeEventListener('click', this.outerClick)
            },
            getEditPanelClass(i) {
                if (i >= this.days.length - 7 && i <= this.days.length - 1) return 'lastWeekOfMonth'
                return i && (i+1) % 7 ? '' : 'lastDayOfWeek'
            }
        }
    }

    const _groupArrayByField = (arr, key) => {
        if (!Array.isArray(arr) || !key) return [] 
        return arr.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv
        }, {})
    }
