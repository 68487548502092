
import RadioSpeech from "./RadioSpeech.vue"
import event_bus from "../../eventBus"
import {mapGetters, mapActions} from 'vuex'
import {RADIO, TETRA} from '../../store/modulesNames'
import {
    GET_TETRA_CONNECT_STATUS,
    GET_TETRA_CALL,
    GET_TETRA_DUPLEX_CALLS,
    GET_TETRA_CONNECTION_PARAMS,
    GET_TETRA_ACTIVE_GROUP,
    GET_TETRA_PTT_PUSHED,
    GET_TETRA_ECHO,
    TETRA_IS_TURN_ON,
} from '../../store/gettersTypes'
import {
    ACT_TETRA_SEND_SPEECH,
    ACT_SET_SPEECH_PACK_RATE,
    ACT_TETRA_PUSH_PTT,
    ACT_TETRA_POP_PTT,
} from '../../store/actionsTypes'

import {CONNECT_STATUS, TX_GRANT} from '../../store/modules/tetra-radio'
import { i18n } from '../../../ext/i18n'

export default {
    name: "TetraSpeech",
    extends: RadioSpeech,
    computed: {
        isOwnCall() {
            return this[GET_TETRA_CALL] && this[GET_TETRA_CALL]['txSSI'] === this[GET_TETRA_CONNECTION_PARAMS].ssi
        },
        granted() {
            let call = this[GET_TETRA_CALL]
            return Boolean(this[GET_TETRA_PTT_PUSHED] && call &&
                this[GET_TETRA_CONNECT_STATUS] !== CONNECT_STATUS.OFFLINE && call.txGrant === TX_GRANT.GRANTED && this.isOwnCall)
        },
        pttPushed() {
          return this[GET_TETRA_PTT_PUSHED]
        },
        radioIsTurnOn() {
            return this[TETRA_IS_TURN_ON]
        },
        getEcho() {
            return this[GET_TETRA_ECHO]
        },
        activeDuplexCall() {
            return this[GET_TETRA_DUPLEX_CALLS][0]
        },
        ...mapGetters(TETRA, [
            TETRA_IS_TURN_ON,
            GET_TETRA_CONNECT_STATUS,
            GET_TETRA_CALL,
            GET_TETRA_CONNECTION_PARAMS,
            GET_TETRA_ACTIVE_GROUP,
            GET_TETRA_PTT_PUSHED,
            GET_TETRA_ECHO,
            GET_TETRA_DUPLEX_CALLS,
        ]),
    },
    methods: {
        sendSpeech(speech) {
            this[ACT_TETRA_SEND_SPEECH]({
                ct: 1,
                group: (this[GET_TETRA_ACTIVE_GROUP] || {}).gssi,
                sd: speech
            })
        },
        sendDuplexSpeech(speech) {
            let ssi = this.activeDuplexCall && this.activeDuplexCall.id
            if (ssi) {
                this[ACT_TETRA_SEND_SPEECH]({
                    ct: 1,
                    sd: speech,
                    ssi,
                })
            }
        },
        subscribeOnIncomingSpeech() {
            event_bus.$off('radio-speech')
            event_bus.$on('radio-speech', this.onIncomingSpeech)
        },
        popPtt() {
            this[ACT_TETRA_POP_PTT]()
        },
        pushPtt() {
            this[ACT_TETRA_PUSH_PTT]()
        },
        ...mapActions(RADIO, [ACT_SET_SPEECH_PACK_RATE]),
        ...mapActions(TETRA, [ACT_TETRA_SEND_SPEECH, ACT_TETRA_PUSH_PTT, ACT_TETRA_POP_PTT]),
    },
}
