import proto from '../../protocol'

import {
    ACT_SCHEDULE_CREDENTIALS_CHECK,
    ACT_SCHEDULE_DELETE_CREDENTIALS,
    ACT_SCHEDULE_UPDATE_CREDENTIALS,
    ACT_SCHEDULE_SYNC_APPOINTMENTS,
    ACT_SCHEDULE_ADD_APPOINTMENT,
    ACT_SCHEDULE_EDIT_APPOINTMENT,
    ACT_SCHEDULE_DELETE_APPOINTMENT,
    ACT_SCHEDULE_GET_APPOINTMENT,
    ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS,
    ACT_SCHEDULE_RESPONSE_TO_INVITATION,
} from '../actionsTypes'

import { MUT_SAVE_MSEXCHANGE_AUTH_RESULT } from '../mutationsTypes'

import { GET_IS_MSEXCHANGE_AUTH } from '../gettersTypes'

const state = {
    isAuthSuccessful: false,
}

const getters = {
    [GET_IS_MSEXCHANGE_AUTH]: (state) => state.isAuthSuccessful,
}


const actions = {
    async [ACT_SCHEDULE_CREDENTIALS_CHECK]({ commit }, payload) {
        let reply
        try {
            reply = await proto.scheduleCredentialsCheck(payload)
            commit(MUT_SAVE_MSEXCHANGE_AUTH_RESULT, true)
        } catch(error) {
            commit(MUT_SAVE_MSEXCHANGE_AUTH_RESULT, false)
            return { error }
        }
        return reply
    },
    async [ACT_SCHEDULE_DELETE_CREDENTIALS](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleDeleteCredentials(payload)
        } catch(error) {
            return { error }
        }
        let { error } = reply
        return error ? { error } : true
    },
    async [ACT_SCHEDULE_UPDATE_CREDENTIALS](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleUpdateCredentials(payload)
        } catch(error) {
            return { error }
        }
        let { error } = reply
        return error ? { error } : true
    },

    async [ACT_SCHEDULE_SYNC_APPOINTMENTS]({ commit }, payload = { type: "exchange"}) {
        let reply
        try {
            reply = await proto.scheduleSyncAppointments(payload)
            commit(MUT_SAVE_MSEXCHANGE_AUTH_RESULT, true)
        } catch(error) {
            commit(MUT_SAVE_MSEXCHANGE_AUTH_RESULT, false)
            return { error }
        }
        return reply
    },

    async [ACT_SCHEDULE_ADD_APPOINTMENT](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleAddAppointment(payload)
        } catch(error) {
            return { error }
        }
        return reply
    },

    async [ACT_SCHEDULE_EDIT_APPOINTMENT](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleEditAppointment(payload)
        } catch(error) {
            return { error }
        }
        return reply
    },
    
    async [ACT_SCHEDULE_DELETE_APPOINTMENT](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleDeleteAppointment(payload)
        } catch(error) {
            return { error }
        }
        return reply
    },    
    async [ACT_SCHEDULE_GET_APPOINTMENT](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleGetAppointment(payload)
        } catch(error) {
            return { error }
        }
        return reply
    },    
    async [ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleGetCalendarAppointments(payload)
        } catch(error) {
            return { error }
        }
        return reply
    },
    
    async [ACT_SCHEDULE_RESPONSE_TO_INVITATION](obj, payload) {
        let reply
        try {
            reply = await proto.scheduleResponseToInvitation(payload)
        } catch(error) {
            return { error }
        }
        return reply
    },    
}

const mutations = {
    [MUT_SAVE_MSEXCHANGE_AUTH_RESULT] (state, value) {
        state.isAuthSuccessful = value
    },
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};