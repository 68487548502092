import module from '../../modules/tetra-radio'

import {
    ACT_TETRA_CONNECT,
    ACT_TETRA_DISCONNECT,
    ACT_TETRA_GET_STATUS,
    ACT_TETRA_LINK_CREATE,
    ACT_TETRA_HANDLE_MSG_EVENT,
    ACT_TETRA_HANDLE_STATUS_EVENT,
    ACT_TETRA_REQUEST_TOKEN,
    ACT_TETRA_GET_PROTO,
    ACT_TETRA_STORE_MESSAGES,
    ACT_TETRA_API_SEND_CMD,
} from '../../actionsTypes'
import {
    MUT_TETRA_SET_CLIENT_PARAMS,
    MUT_TETRA_SET_CONNECT_STATUS,
    MUT_TETRA_SET_LINK_CREATED,
    MUT_TETRA_SET_LOCATED,
    MUT_TETRA_SET_CALL,
    MUT_TETRA_SET_GROUPS,
    MUT_TETRA_SET_CONNECT_ERROR,
    MUT_TETRA_SET_TOKEN,
} from '../../mutationsTypes'

import { CONNECT_STATUS, CONNECT_ERROR } from '../../modules/tetra-radio'

import mainproto from '../../../protocol'
import RadioIOClient from '../../../api/roschat/RadioIOClient'


let proto = null


const actions = {
    [ACT_TETRA_CONNECT]: async ({state, dispatch, commit, getters}, payload = {}) => {
        const life = (new Date()).getTime()
        const connectionData = {...payload, ...{life}}
        let { ssi, name, pushedByBtn } = connectionData
        try {
            const isConnected = proto && proto.isConnected
            if (!isConnected) {
                proto = await dispatch(ACT_TETRA_GET_PROTO)
                const radioServer = getters['getRadioServer']
                await proto.connect(radioServer)
            }
            if (!state.token || pushedByBtn) {
                const params = { ssi, name }
                await dispatch(ACT_TETRA_REQUEST_TOKEN, params)
            }
            commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.CONNECTING)
            const token = state.token
            if (!connectionData.force) connectionData.force = true
            const response = await proto.tetraRadioConnect({...connectionData, token})
            const { status, error } = response
            commit(MUT_TETRA_SET_CLIENT_PARAMS, connectionData)
            if (status) {
                if (status !== CONNECT_STATUS.READY) {
                    throw status
                }
                commit(MUT_TETRA_SET_CONNECT_STATUS, status)
                dispatch(ACT_TETRA_LINK_CREATE)
            }
            if (error) {
                console.log("🚀 ~ file: tetra-radio.js:434 ~ [ACT_TETRA_CONNECT]: ~ error:", error)
                if (error === CONNECT_ERROR.TOKEN_EXPIRED) {
                    commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.ERROR)
                    commit(MUT_TETRA_SET_CONNECT_ERROR, error)
                    commit(MUT_TETRA_SET_LOCATED, {})
                    commit(MUT_TETRA_SET_CLIENT_PARAMS, {})
                    commit(MUT_TETRA_SET_CONNECT_STATUS)
                    commit(MUT_TETRA_SET_LINK_CREATED)
                    commit(MUT_TETRA_SET_GROUPS, {})
                    commit(MUT_TETRA_SET_CALL)
                }
            }
        } catch (e) {
            if (e === CONNECT_ERROR.TOKEN_EXPIRED) {
                console.log("🚀 ~ file: tetra-radio.js:430 ~ [ACT_TETRA_CONNECT]: ~ CONNECT_ERROR.TOKEN_EXPIRED:", CONNECT_ERROR.TOKEN_EXPIRED)
                // const params = { ssi, name }
                // await dispatch(ACT_TETRA_REQUEST_TOKEN, params)
                // dispatch(ACT_TETRA_CONNECT, payload)
            } else {
                commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.ERROR)
                commit(MUT_TETRA_SET_CONNECT_ERROR, e)
            }
        }
    },
    [ACT_TETRA_API_SEND_CMD]: async ({state, commit}, {level, msgType, parms = {}}) => {
        const { name, ssi, life } = state
        const cmd = {
            a: { system: name, ssi, life },
            level,
            msgType,
            parms
        }
        let result = await proto.tetraRadioSendCmd({name, ssi, cmd})

        if (commit && result && result === 'token-expired') {
            commit(MUT_TETRA_SET_LOCATED, {})
            commit(MUT_TETRA_SET_CLIENT_PARAMS, {})
            commit(MUT_TETRA_SET_CONNECT_STATUS)
            commit(MUT_TETRA_SET_LINK_CREATED)
            commit(MUT_TETRA_SET_GROUPS, {})
            commit(MUT_TETRA_SET_CALL)
        }
    },
    [ACT_TETRA_GET_PROTO]: async ({dispatch}) => {
        if (proto) return proto

        proto = new RadioIOClient()


        proto.on('tetra-radio-status-change-event', (payload) => {
            dispatch(ACT_TETRA_HANDLE_STATUS_EVENT, payload)
        })

        proto.on('tetra-radio-message-event', (payload) => {
            dispatch(ACT_TETRA_HANDLE_MSG_EVENT, payload)
        })

        return proto
    },
    [ACT_TETRA_DISCONNECT]: async ({state, commit}) => {
        const {name, ssi} = state
        await proto.tetraRadioDisconnect({name, ssi})
        commit(MUT_TETRA_SET_LOCATED, {})
        commit(MUT_TETRA_SET_CLIENT_PARAMS, {})
        commit(MUT_TETRA_SET_CONNECT_STATUS)
        commit(MUT_TETRA_SET_LINK_CREATED)
        commit(MUT_TETRA_SET_GROUPS, {})
        commit(MUT_TETRA_SET_CALL)
    },
    [ACT_TETRA_GET_STATUS]: async ({state, commit}) => {
        const {name, ssi} = state
        commit(MUT_TETRA_SET_CONNECT_STATUS, await proto.tetraRadioGetStatus({name, ssi}))
    },
    [ACT_TETRA_STORE_MESSAGES]: ({}, {ssi, msgs}) => {
        let _msgs = JSON.parse(localStorage.getItem('TETRA_MSGS')) || {}
        // console.log("🚀 ~ file: tetra-radio.js:498 ~ _msgs:", _msgs)
        _msgs[ssi] = msgs
        localStorage.setItem('TETRA_MSGS', JSON.stringify(_msgs))
    },
    [ACT_TETRA_REQUEST_TOKEN]: async ({commit}, params) => {
        try {
            const replyObj = await mainproto.radioGetToken({type: 'tetra', params})
            const isToken = replyObj.hasOwnProperty('token')
            const token = isToken ? replyObj.token : ''
            commit(MUT_TETRA_SET_TOKEN, token) }
        catch {}
    },
}

Object.assign(module.actions, actions)

export default module