import { render, staticRenderFns } from "./DelChatMessage.vue?vue&type=template&id=3b24164d&scoped=true&lang=pug&"
import script from "./DelChatMessage.vue?vue&type=script&lang=js&"
export * from "./DelChatMessage.vue?vue&type=script&lang=js&"
import style0 from "./DelChatMessage.vue?vue&type=style&index=0&id=3b24164d&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b24164d",
  null
  
)

export default component.exports