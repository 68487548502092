import Vue from 'vue';
import Vuex from 'vuex';
import login from '../modules/login';
import contactsStore from '../modules/contacts';
import chatsStore from '../modules/chats';
import chatStore from '../modules/chat';
import BlobStore from '../modules/blob';
import ajax from '../modules/ajax';
import channelsStore from '../modules/channels';
import channelStore from '../modules/channel';
import acs from '../modules/acs';
import calls from '../modules/calls';
import content_manager from '../modules/content-manager';
import notifications from '../modules/notifications';
import info from '../modules/info';
import alarm from '../modules/alarm';
import bots from '../modules/bots';
import notice from '../modules/notice'
import videoConf from '../modules/video-conf'
import locations from '../modules/locations';
import modal from '../modules/modal';
import radio from '../modules/radio';
import rc_radio from '../modules/rc-radio';
import userProfile from '../modules/userprofile';
import dlp from '../modules/dlp';
import data_message_builder from '../modules/data-message-builder';
import integrations from '../modules/integrations'

import socket from './modules/socket'
import phone from './modules/phone'
import phone_controller from './modules/phone-controller'
import vc_conf_new from './modules/video-conf-new'
import video_conf_videomost from './modules/video-conf-videomost'
import video_conf_roschat from './modules/video-conf-roschat'
import userData from './modules/userdata'
import clientdata from './modules/clientdata'
import asistants from './modules/asistants'
import tetra from './modules/tetra-radio'


import '../main/storeSubscribers'

import {
    LOGIN,
    SOCKET,
    CONTACTS,
    CHATS,
    CHAT,
    BLOB,
    AJAX,
    CHANNELS,
    CHANNEL,
    USERDATA,
    ACS,
    CALLS,
    CONTENT_MANAGER,
    NOTIFICATIONS,
    INFO,
    ALARM,
    BOTS,
    PHONE,
    CLIENTDATA,
    LOCATIONS,
    MODAL,
    RADIO,
    TETRA,
    RC_RADIO,
    VIDEO_CONF,
    ASISTANTS,
    PHONE_CNTL,
    USERPROFILE,
    VIDEO_CONF_NEW,
    VM_CONF,
    DLP,
    DATA_MESSAGE_BUILDER,
    INTEGRATIONS,
    ROSCHAT_CONF,
} from '../modulesNames';

import createPersistedState from 'vuex-persistedstate'
// #if process.env.WEBPACK_BUILD_TARGET === 'electron'
// import { ElectronSyncer } from '../storePlugins'
// import electronSyncList from './electronSyncList'
// const electronSyncer = new ElectronSyncer(electronSyncList)
// #endif
// import event_bus from "../eventBus"

Vue.use(Vuex);

const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: [
                `${LOGIN}.tokensIsSet`,
                `${LOGIN}.ouath2type`,
                `${CHATS}.chatsSavedData`,
                `${INFO}.mustBeOpened`,
                'content_manager.sideType',
                `${LOCATIONS}.locationsConfig`,
                `${RADIO}.currentRadioIndex`,
                `${RADIO}.settings`,
                `${RC_RADIO}.channelId`,
                `${TETRA}.messages`,
                `${TETRA}.unwatchedMessages`,
                `${TETRA}.usersList`,
                `${CLIENTDATA}.locale`,
                `${CLIENTDATA}.theme`,
                `${CLIENTDATA}.fontSettings`,
                `${CLIENTDATA}.newClientInfo`,
                `${CLIENTDATA}.nextNewClientRequest`,
                `${CLIENTDATA}.newClientInfoViewed`,
                `${USERPROFILE}`,
                `${USERDATA}.searchedContacts`,
                `${USERDATA}.searchedContactsNew`,
                `${USERDATA}.volumeNotification`,
                `${USERDATA}.volumeRing`,
                `${USERDATA}.volumeRadio`,
                `${USERDATA}.volumeAudio`,
                `${USERDATA}.volumeMicrophone`,
                `${USERDATA}.webcameraEnable`,
                `${USERDATA}.microphoneEnable`,
                `${USERDATA}.activeSpeakers`,
                `${USERDATA}.activeMicrophone`,
                `${USERDATA}.activeWebcamera`,
                `${USERDATA}.mediaDevices`,
                `${USERDATA}.birthdayNotify`,
                `${CONTACTS}.birthdays_watched`,
            ],
            filter: (data => {
                return true
            })
        }),
        // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//         (store) => electronSyncer.subscribeOnStore(store),
        // #endif
    ],
    modules: {
        [LOGIN]: login,
        [SOCKET]: socket,
        [CONTACTS]: contactsStore,
        [CHATS]: chatsStore,
        [CHAT]: chatStore,
        [BLOB]: (new BlobStore()).store,
        [AJAX]: ajax,
        [CHANNELS]: channelsStore,
        [CHANNEL]: channelStore,
        [USERDATA]: userData,
        // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//         notice,
        // #endif
        [ACS]: acs,
        [INFO]: info,
        [CALLS]: calls,
        [CONTENT_MANAGER]: content_manager,
        [NOTIFICATIONS]: notifications,
        [ALARM]: alarm,
        [BOTS]: bots,
        [PHONE]: phone,
        [LOCATIONS]: locations,
        [CLIENTDATA]: clientdata,
        [MODAL]: modal,
        [RADIO]: radio,
        [TETRA]: tetra,
        [RC_RADIO]: rc_radio,
        [VIDEO_CONF]: videoConf,
        [ASISTANTS]: asistants,
        [PHONE_CNTL]: phone_controller,
        [USERPROFILE]: userProfile,
        [VIDEO_CONF_NEW]: vc_conf_new,
        [VM_CONF]: video_conf_videomost,
        [ROSCHAT_CONF]: video_conf_roschat,
        [DLP]: dlp,
        [DATA_MESSAGE_BUILDER]: data_message_builder,
        [INTEGRATIONS]: integrations,
    }
})

export default store;

