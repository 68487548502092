//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalDialog from '../ModalDialog.vue'
import modal_mixin from '../modal-mixin'
import CustomDropDown from "../../custom/CustomDropDown.vue"
import ModalBtn from "../ModalBtn.vue"

import { INTEGRATIONS, USERDATA } from '../../../store/modulesNames'
import { 
    ACT_SCHEDULE_CREDENTIALS_CHECK,
    ACT_SCHEDULE_DELETE_CREDENTIALS,
    ACT_SCHEDULE_UPDATE_CREDENTIALS,
    ACT_SAVE_INTEGRATION_SETTINGS,
    ACT_CLEAR_INTEGRATION_SETTINGS,
}  from "../../../store/actionsTypes"
import { GET_INTEGRATION_SETTINGS } from '../../../store/gettersTypes'
import { mapActions, mapGetters } from '../../../../vuex'

import { INTEGRATION_NAMES } from '../../../constants'
const integrationName = INTEGRATION_NAMES.MS_EXCHANGE

export default {
    name: "MSExchangeSettings",
    components: { ModalDialog, CustomDropDown, ModalBtn },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                pivotY: 0.40,
                pivotX: 0.65,
                width: 440,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 300px;"
            },
            cancelChange: false,
            login: '',
            password: '',
            isCheckedOK: false,
            editMode: false,
            notification: { message: '', class: ''}
        }
    },
    mounted() {
        const currentSettings = this[GET_INTEGRATION_SETTINGS](integrationName)
        let { login } = currentSettings
        if (login) {
            this.login = login
            this.editMode = true
        }
    },
    computed: {
        isAddBtnDisabled() {
            return !this.selectedVal
        },
        isSaveBtnEnabled() {
            return this.login && this.login.length && this.password && this.password.length
        },
        isDeleteBtnEnabled() {
            return this.login && this.login.length
        },
        passwordPlaceholderText() {
            return this.editMode ? this.$t('integrations.change-password-placeholder') : this.$t('integrations.password')
        },
        ...mapGetters(USERDATA, [GET_INTEGRATION_SETTINGS],)
    },
    methods: {
        async saveBtn() {
            await this.checkAuth()
            if (!this.isCheckedOK) return
            const credentials = { login: this.login, password: this.password }
            let payload = { type: "exchange", credentials }
            let res = await this[ACT_SCHEDULE_UPDATE_CREDENTIALS](payload)
            if (res) {
                this[ACT_SAVE_INTEGRATION_SETTINGS]({ integrationName, settings: credentials })
            }
            this.modalClose()
        },
        cancelBtn() {
            this.cancelChange = true
            this.modalClose()
        },
        selectMSVersion(val) {
            this.selectedMSVersion = val
        },
        selectAuth(val) {
            this.selectedAuth = val
        },
        async checkAuth() {
            this.notification = {}
            const login = this.login, password = this.password
            const payload = { type: "exchange", credentials: { login, password } }
            const reply = await this[ACT_SCHEDULE_CREDENTIALS_CHECK](payload)
            let { error } = reply
            if (!error) {
                // const successMsg = this.$t('settings.authorization') + this.$t('settings.successfull')
                // this.$nextTick(() => this.notification = { message: successMsg, class: 'greenColor'})
                this.$nextTick(() => this.notification = { message: '', class: 'greenColor'})
                this.isCheckedOK = true
            }
            else {
                const failedMsg = this.$t('settings.authorization') + this.$t('settings.failed')
                this.$nextTick(() => this.notification = { message: failedMsg, class: 'redColor'})
            }
        },
        async deleteBtn() {
            let payload = { type: "exchange" }
            let isOk = await this[ACT_SCHEDULE_DELETE_CREDENTIALS](payload)
            if (isOk) {
                this.editMode = false
                this[ACT_CLEAR_INTEGRATION_SETTINGS]({ integrationName })
                this.$nextTick(() => {
                    this.notification = { message: this.$t('integrations.delete-login-success'), class: 'greenColor'}
                    this.login = ''
                    this.password = ''
                })
                // setTimeout(() => this.modalClose(), 3000)
            } else {
                this.$nextTick(() => this.notification = { message: this.$t('integrations.delete-login-failed'), class: 'redColor'})
            }
        },                
        ...mapActions(INTEGRATIONS, [    
            ACT_SCHEDULE_CREDENTIALS_CHECK,
            ACT_SCHEDULE_DELETE_CREDENTIALS,
            ACT_SCHEDULE_UPDATE_CREDENTIALS,
        ]),
        ...mapActions(USERDATA, [ACT_SAVE_INTEGRATION_SETTINGS, ACT_CLEAR_INTEGRATION_SETTINGS])            
    },
}
